import React, { useEffect, useState } from "react";
import "./AntMediaFiles.css";
import { classByAgent, isMobileAgent, namefile, fileExtension } from "../../../utils/helpers";
import { upLoadFiles, getLoadFiles } from "../../../services/api"
import { getKey } from "../../../utils/localStorage"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "./Spinner";
import t from "../../../utils/translations";
import {sendMessageIcon, fileTextIcon, downloadFileIcon, closeComponent} from "./AntMediaIcons"

function AntMediaFiles({ showFiles, setShowFiles, colorPrimary, allFiles }) {
  const [name, setName] = useState("load_file");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadFiles, setLoadFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const sizeIcon = isMobileAgent() ? 28 : 25
  const color = colorPrimary.slice(1)


  const scroller = () => setTimeout(() => document.getElementById("inboxFiles") &&
    document.getElementById("inboxFiles").scrollTo(0, document.getElementById("inboxFiles").scrollHeight), 500);

  const handleChange = (e) => {
    e.preventDefault()
    const fileLocal = e.target.files[0]
    if (fileLocal.size >= 10000000) {
      toast.error(t("fileWeightExceeded"), { closeOnClick: true, autoClose: 2000 });
      setSelectedFile(null)
    }
    else setSelectedFile(fileLocal)
  }
  const guid = getKey("issueGuid")
  useEffect(() => {
    getLoadFiles(guid).then(({ response }) => {
      setLoadFiles(response.FilesByOperator)
    })
    scroller()
  }, [allFiles])


  const sendFile = (e) => {
    e.preventDefault()
    const formFile = new FormData()
    formFile.append("Guid", guid)
    formFile.append("from", "client")
    formFile.append('file', selectedFile)
    upFiles(formFile)
  }

  const upFiles = (body) => {
    setLoading(true)
    upLoadFiles(body)
      .then(({ response, success }) => {
      
        if (response && success) {
          toast.success(t("fileSent"), { closeOnClick: true, autoClose: 2000 });
          setSelectedFile(null)
        }
        else if (response === "Incompatible file format") {
          toast.error(t("IncompatibleFileFormat "), { closeOnClick: true, autoClose: 2000 });
          setSelectedFile(null)
        }
        else if (response === "file weight exceeded") {
          toast.error(t("fileWeightExceeded"), { closeOnClick: true, autoClose: 2000 });
          setSelectedFile(null)

        }
        setLoading(false)
      }
      )
  }

  return (
    <div className={classByAgent("amFilesContainer")} id="fileContainer" >
      <div
        className={classByAgent("amFileContainerInner")}
        style={{ marginTop: showFiles ? "0px" : "100%" }}
      >
        <div className="amChatClose" onClick={() => setShowFiles(false)}>
         {closeComponent("#6D6D6D", "20px", "20px")}
        </div>
        <div className="amSenderFileContainer">
          <div className="amFilesTitle">
            <p>{t("receivedFiles")} </p>
          </div>
          <>
            <div className="amFilesReceivedIn" id="inboxFiles">
              {loadFiles?.length == 0 ?
                <h3>{t("notFiles")}</h3> :

                <div className="amFileReceivedContainer">
                  {loadFiles && loadFiles.map(files =>
                    <>
                      <div className="amFileReceivedInner">
                        <div className="amFileDetail">
                          <div className="amFilesIconReceivedContainer">
                            {fileTextIcon("#527FC0", "20px", "20px" )}
                          </div>
                          <div className="fileReceivedInfo">
                            <p>{namefile(files.Name)}.{fileExtension(files.Name)}</p>
                        
                          </div>
                        </div>
                        <a download={files.Name} title="download" href={files.Url} target="_blank">
                              {downloadFileIcon(`${colorPrimary}`, "15px", "15px")}
                        </a>
                      </div>
                    </>
                  )}
                </div>

              }
            </div>
          </>

          {!selectedFile ?
            <div className="amInputFiles">
              <input type="file" accept={isMobileAgent() ? ".jpg, .jpeg, .png" :  ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,.pdf,.txt,.jpg,.png,.jpeg,.doc,.xlsx"}
               name={name} id={name} className="amInputFiles_input" onChange={(e) => handleChange(e)} />
              <div className="amInputFileUpLoad">
                <label htmlFor={name} className="amFilesLabel">
                  <div className="amfileTextupload">
                    <p>{t("addFile")}</p>
                  </div>
                </label>
              </div>
            </div>
            :

            <div className="amFileSendContainer">
             { loading ? <Spinner size="30px" color={colorPrimary} marginLeft ={"45%"}/> :
              <>
              <div className="amFileSendInner">
                <div className="amFilesIconContainer">
                  {fileTextIcon("#527FC0", sizeIcon, sizeIcon)}
                </div>
                <div className="fileSelectedInfo">
                  <p>{namefile(selectedFile?.name)}.{fileExtension(selectedFile?.name)}</p>
                  <p>{(selectedFile?.size / 1048576).toFixed(1)}Mb</p>

                </div>
              </div>
                <div onClick={() => setSelectedFile(null)}>
                {closeComponent("#6D6D6D", "25px", "25px")}
                </div>
             {/*  <img
                src="https://icongr.am/feather/x.svg?size=25px&color=6D6D6D"
                
              /> */}
              <div className="amFilesIconContainer" onClick={sendFile}>
                {sendMessageIcon(`${colorPrimary}`, "25px", "25px")}
              </div>
              </> }
            </div>
          }
        </div>

      </div>
      <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick={false} rtl={false} pauseOnFocusLoss draggable={false} pauseOnHover />
    </div>
  );
}

export default AntMediaFiles;
