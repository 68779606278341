export const createAppointmentQuotaRange = (max) => {
	let result = [{
		key: "1 persona",
		value: 1
	}];
	let iterator = 2;
	while (iterator <= max) {
		result.push({
			key: `${iterator} personas`,
			value: iterator
		});
		iterator ++;
	}
	return result;
};