export const getRoutes = (landing, landingPath) => {
	const shouldShowRecomendations = landing.AllowRecomendations && landing.Recomendation !== null && `/${landingPath}/1`;
	const ShouldShowLocations = landing.AllowLocations && `/${landingPath}/1-0`;
	const ShouldShowStores = landing.AllowStore && landing.Store && landing.Store.length > 1 && `/${landingPath}/1-1`;
	const shouldShowDepartment = landing.AllowDepartment && landing.Departments && landing.Departments.length > 1 && `/${landingPath}/2`;
	const shouldShowQuestions = landing.AllowQuestion && landing.Questions && !landing.MergeQuestions && landing.Questions.length > 0 && `/${landingPath}/3`;
	const shouldShowVideoCallOT = landing.AllowVideoCall && landing.IdIssueType.IdIssueType === 1 && landing.MediaServer !== "mediaserver" && `/${landingPath}/4`;
	const shouldShowVideoCallPJs = landing.AllowVideoCall && landing.IdIssueType.IdIssueType === 1 && landing.MediaServer === "mediaserver" && `/${landingPath}/4-1`;
	const shouldShowAppointment = landing.IdIssueType.IdIssueType === 2 && `/${landingPath}/4-2`;
	const shouldShowFeedbacks = landing.AllowFeedbacks && `/${landingPath}/5`;
	const showThanks = landing.AllowShare && `/${landingPath}/6`;
	const redirectOutOfLS = landing.AllowRedirection && "externalURL";
	const returnToHome = `/${landingPath}/`;

  const validations = [
    shouldShowRecomendations,
    ShouldShowLocations,
    ShouldShowStores,
    shouldShowDepartment,
    shouldShowQuestions,
    shouldShowAppointment,
    shouldShowVideoCallOT,
    shouldShowVideoCallPJs,
    shouldShowFeedbacks,
    showThanks,
    redirectOutOfLS,
    returnToHome,
  ].filter((validation) => !!validation && validation);
  return validations;
};

export const EMAIL_REGEXP =
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}" +
  "[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";

export const DNI_REGEXP = "^[0-9]{6,8}$";

export const PHONE_REGEXP = "^[0-9]{8,15}$";

export const URL_REGEXP = new RegExp(
  "^(https?:\\/\\/)?" +
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

export const URL_REGEX = (text) => {
  let validURL = false;
  let isHTTP = text.slice(0, 4).toLowerCase();
  let isHTTPS = text.slice(0, 5).toLowerCase();
  let isWWW = text.slice(0, 3).toLowerCase();
  if (isHTTP === "http") validURL = true;
  if (isHTTPS === "https") validURL = true;
  if (isWWW === "www") validURL = true;
  return validURL;
};
