import React, { useEffect, useState } from "react";

import Locations from "./Locations";
import SelectorItem from "../../components/SelectorItem";
import Spinner from "../../components/Spinner";

import { getCities, getCountries, getProvinces } from "../../services/api";
import { landingOnLoad } from "../../utils/landingLoad";

const LocationsContainer = ({ location, history }) => {
	const [loading, setLoading] = useState(null);
	const [localState,] = useState(location.state);
	const [cities, setCities] = useState(null);
	const [countries, setCountries] = useState(null);
	const [provinces, setProvinces] = useState(null);
	const [selectedCity, setSelectedCity] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedProvince, setSelectedProvince] = useState(null);

	useEffect(() => {
		landingOnLoad(localState, history, () => setLoading(false), () => setLoading(true))
		getCountries().then(({ success, response }) => {
			if (success) setCountries(response);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, localState]);

	const createCountriesOptions = () => countries && countries.map(country =>
		<SelectorItem key={country.IdCountry} name={country.Name} value={country.IdCountry} />
	);

	const createProvincesOptions = () => provinces && provinces.map(province => {
		return { label: province.Name, value: province.IdRegion };
	});

	const createCitiesOptions = () => cities && cities.map(city => {
		return { label: city.Name, value: city.IdCity };
	});

	const handleSelectedCountry = IdCountry => {
		setSelectedCountry(IdCountry);
		setSelectedProvince(null);
		setSelectedCity(null);
		getProvinces({ IdCountry }).then(({ success, response }) => {
			if (success) {
				const provincesSorted = response.sort((province1, province2) => {
					if (province1.Name < province2.Name) { return -1; }
					if (province1.Name > province2.Name) { return 1; }
					return 0;
				});
				setProvinces(provincesSorted);
			}
		});
	};

	const handleSelectedProvince = IdRegion => {
		setSelectedProvince(IdRegion);
		setSelectedCity(null);
		getCities({ IdCountry: selectedCountry, IdRegion: IdRegion.value }).then(({ success, response }) => {
			if (success) {
				const citiesSorted = response.sort((city1, city2) => {
					if (city1.Name < city2.Name) { return -1; }
					if (city1.Name > city2.Name) { return 1; }
					return 0;
				});
				setCities(citiesSorted);
			}
		});
	};

	const handleSelectedCity = IdCity => {
		setSelectedCity(IdCity);
	};

	const handleSubmit = event => {
		event && event.preventDefault();

		const stateWithSelectedOptions = { ...localState };
		const { routes } = stateWithSelectedOptions;
		stateWithSelectedOptions.submit.IdCountry = selectedCountry;
		stateWithSelectedOptions.submit.IdRegion = selectedProvince.value;
		// stateWithSelectedOptions.submit.IdCity = selectedCity.value;

		const nextRoute = routes.shift();

		history.push({
			pathname: nextRoute,
			state: stateWithSelectedOptions
		});
	};

	return loading ? <Spinner /> : (
		<Locations
			createCitiesOptions={createCitiesOptions}
			createCountriesOptions={createCountriesOptions}
			createProvincesOptions={createProvincesOptions}
			handleSelectedCity={handleSelectedCity}
			handleSelectedCountry={handleSelectedCountry}
			handleSelectedProvince={handleSelectedProvince}
			handleSubmit={handleSubmit}
			icon={localState.data.Icon}
			loading={loading}
			selectedCity={selectedCity}
			selectedCountry={selectedCountry}
			selectedProvince={selectedProvince}
			showFooter={localState.data.ShowFooter}
		/>
	);

};

export default LocationsContainer;