
import React from "react";
import PageWrapper from "./PageWrapper";
import Button from "./Button";
import Error from "./Error";
import t from "../utils/translations";

const VideocallError = ({
    showFooter, 
    showHeader, 
    errorMessage, 
    errorTitle, 
    twoButtons,
    rightButtonText,
    rightButtonAction,
    leftButtonText,
    leftButtonAction,
}) =>
    <PageWrapper className="smallMarginHorizontal columnContainerWithFlex" showFooter={showFooter} showHeader={showHeader}>
        <Error errorMessage={errorMessage} errorTitle={errorTitle} secondErrorMessage={t("recommendation")} />
            {twoButtons ? (
				<div className="twoButtons">
					<Button className="alignSelfCenter whiteSpaceNormal" size="large" onClick={leftButtonAction}>
						{leftButtonText}
					</Button>
					<Button className="alignSelfCenter whiteSpaceNormal" size="large" onClick={rightButtonAction} >
			            {rightButtonText}						
					</Button>
				</div>
			) : (
                <Button className="alignSelfCenter whiteSpaceNormal" size="large"onClick={rightButtonAction} >
                    {rightButtonText}
                </Button>
			)}
    </PageWrapper>

export default VideocallError;