import { IonIcon, IonImg } from '@ionic/react';
import React, { useState } from 'react'
import { alertCircleOutline } from 'ionicons/icons'
import logoSafari from './imgs/Safari.png'
import pegarUrl from './imgs/pegarIOS.png'


export const BrowserChange = () => {
  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };
  const getURL = () => {
    let url = window.location.href.split("/")
    let urls = url.pop()
    return url.join("/")
  }
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    copyToClipboard(getURL())
    setClicked(!clicked)
  }
  return (
    <div style={{ padding: "15px", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "space-around", height: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <IonIcon style={{ fontSize: "40px" }} icon={alertCircleOutline} />
      </div>
      <h4 style={{ fontSize: "20px" }}>Tu navegador no es compatible.</h4>
      <p>Sigue los siguientes pasos para conectarte desde safari y tener una mejor experiencia.</p>
      <hr />
      <p style={{ marginTop: "10px" }} >1. Copia el enlace utilizando este botón</p>
      <div style={{ display: "flex" }}>
        <button
          style={{ padding: "15px", margin: "auto", color: clicked ? "white" : "blue", backgroundColor: clicked ? "lightgreen" : "white", borderRadius: "15px" }}
          onClick={() => handleClick()}>
          {clicked ? "ENLACE COPIADO" : "COPIAR ENLACE"}
        </button>
      </div>
      <p style={{ marginBottom: "0" }}>2. Buscar Safari en tu telefono {<br />} y abre el navegador</p>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
        <img src={logoSafari} style={{ maxHeight: '60px', margin: "auto" }} />
      </div>
      <p>3. Pega el enlace copiado</p>
      <img src={pegarUrl} style={{ width: '100%' }} />

    </div>
  )
}

export default BrowserChange