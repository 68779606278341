import React from "react";
import { IonIcon } from "@ionic/react";
import Iframe from "react-iframe";
import { cameraReverseOutline } from "ionicons/icons";
import "opentok-solutions-css";

import "./OpentokCall.scss";
import Spinner from "../../../components/Spinner";
import t from "../../../utils/translations";
import Alert from "../../../components/Alert";
import Fab from "../../../components/Fab";
import Modal from "../../../components/Modal";
import PageWrapper from "../../../components/PageWrapper";
import CallLayout from "../../../components/CallLayout"
import ScreenPermits from "../../../components/ScreenPermits"
import VideocallError from "../../../components/VideocallError";
import Croma from  "../../../components/Croma";
import * as helpers from "./RenderHelper";
import {sendEvent} from "../../../utils/helpers";
const OpentokRender = ({
    options,
    localState,
    loading,
    callPermitScreen,
    preloadWaitingScreen,
    setShowFab,
    showFab,
    openExternalLink,
    showModal,
    onClickHideModal,
    alerts,
    setAlerts,
    endCall,
    cancelTicket,
    closeConnection,
    timeOutRef,
    setFirstWaitingPeriod,
    storeIsClosed,
    onSelectMediaDevices,
    setPreloadWaitingScreen,
    toggleLocalAudio,
    onPressEndCallButton,
    toggleLocalVideo,
    isOnViewChatButton,
    toggleChat,
    toggleCamera,
    setOptions,
    link,
    isFirstWaitingPeriod,
    callError,
    generateOfflineIssue,
    onClickRestart,
    onClickChooseAnother,
    setSecondWaitingPeriod,
    isSecondWaitingPeriod,
    location,
}) => {
    const onAcceptEndCall = () => {
        setAlerts({...alerts, endCall: false});
        sendEvent("fin_videollamada", "fin_videollamada");
        helpers.activeCameraSubscribers(options) ? endCall() : cancelTicket();
    };
    const onOpenChatMessage = () => {
        setOptions({ ...options, showChat: true });
        setAlerts({...alerts, newMessage: false});
    };

    const onAccpetStillThere = () => {
        setAlerts({...alerts, stillThere: false});
        clearTimeout(timeOutRef.current);
        setSecondWaitingPeriod (false)
        setTimeout(() => {
            if (!helpers.activeCameraSubscribers(options)) setFirstWaitingPeriod(false)
        }, 390000);
    };
    
    const onAcceptNotCameraFounded = () => setAlerts({...alerts, cameraNotFounded: false});

    const onIgnoreChatMessage = () => setAlerts({...alerts, newMessage: false});
    const onCancelEndCall = () => setAlerts({...alerts, endCall: false});
    const onDeclineStillThere = () => {
        cancelTicket();
        closeConnection();
    };

    const chromaImg = localState.data.Images.find(img => img.Type === "Chroma");

    const showFooter = helpers.inWaitingScreen(options) || !callPermitScreen || preloadWaitingScreen;
    const showHeader = helpers.inWaitingScreen(options) || !callPermitScreen || preloadWaitingScreen;
    const closedStoreMessage = localState.data.OfflineBody || t("contactMe");
    const spinner = <Spinner />;
    const error = (
            errorMessage, 
            rightButtonText, 
            rightButtonAction,
            twoButtons,
            secondButtonText,
            secondButtonAction,
        ) =>
        <VideocallError
            twoButtons={twoButtons}
            showFooter={localState.data.ShowFooter} 
            showHeader={true}
            errorMessage={errorMessage}
            errorTitle={t("attention")}
            rightButtonText={rightButtonText}
            rightButtonAction={rightButtonAction}
            leftButtonText={secondButtonText}
            leftButtonAction={secondButtonAction}
        />;

        
    const call = 
        <PageWrapper showFooter={showFooter} showHeader={showHeader} localState={localState} top={false} fullWidth={callPermitScreen}>
                <div className="App">
                    <div className="App-video-container">
                        <Fab
                            closeButton
                            className="fabOnVideo"
                            handleClose={() => setShowFab(false)}
                            hide={!showFab}
                            horizontal="start"
                            text={(localState && localState.data.CustomShareLabel) || t("openLink")}
                            vertical="top"
                            onClick={openExternalLink}
                        />
                        <Modal isOpen={showModal} onClickClose={onClickHideModal}>
                            <Iframe height="100%" sandbox="" url={link}width="100%"/>
                        </Modal>
                        <div className={helpers.cameraPublisherClass(options)} id="cameraPublisherContainer" />
                        <div 
                            style={{ display: localState.data.AllowCroma && "none" }} 
                            className={helpers.cameraSubscriberClass(options)} 
                            id="cameraSubscriberContainer" 
                        />
                        {options.meta && options.meta.subscriber.camera && options.active && chromaImg
                            ? <Croma
                                backgroundImage={chromaImg}
                                width={600}
                                height={411}
                                stream={document.getElementById('cameraSubscriberContainer').getElementsByClassName('OT_video-element')[0].srcObject}
                            />
                            : null
                        }
                        <div className={helpers.localChatClass(options)} id="chat" />
                        <div className={helpers.screenPublisherClass(options)} id="screenPublisherContainer" />
                        <div className={helpers.screenSubscriberClass(options)} id="screenSubscriberContainer" />
                        <Alert
                            buttons={helpers.videoCallButtons(onCancelEndCall, onAcceptEndCall)}
                            header={t("confirmation")}
                            isOpen={alerts.endCall}
                            message={`${t("areYouSureYouWantTo")} ${helpers.activeCameraSubscribers(options) ? t("endCall") : t("cancelTicket")}`}
                            onDidDismiss={onCancelEndCall}
                        />

                        {!helpers.activeCameraSubscribers(options) && isSecondWaitingPeriod &&
                            <Alert
                                buttons={helpers.stillThereButton(onDeclineStillThere, onAccpetStillThere)}
                                header={t("confirmation")}
                                isOpen={alerts.stillThere}
                                message={t("wantToKeepWaiting")}
                                onDidDismiss={() => setAlerts({...alerts, stillThere: false})}
                            />}
                        <Alert
                            buttons={helpers.notFoundCameraButton(onAcceptNotCameraFounded)}
                            header={t("alert")}
                            isOpen={alerts.cameraNotFounded}
                            message={t("deviceNotFounded")}
                            onDidDismiss={onAcceptNotCameraFounded}
                        />
                        <Alert
                            buttons={helpers.newChattMessageButton(onIgnoreChatMessage, onOpenChatMessage)}
                            isOpen={alerts.newMessage}
                            message={t("newChatMessage")}
                            onDidDismiss={onIgnoreChatMessage}
                        />
                        {(loading || (!options.connected && !options.meta)) && (
                            <Spinner className="fullHeight" />
                        )}
                        {!callPermitScreen && (
                            
                            <ScreenPermits
                                onClick={onSelectMediaDevices}
                                setPreloadWaitingScreen={setPreloadWaitingScreen}
                                location={ location}
                                />
                    
                        )}
                        {(preloadWaitingScreen|| (options.active && !helpers.activeCameraSubscribers(options))) && (
                            <CallLayout
                                spinner={localState.data.CustomLoadingGif}
                                callLoaded={options.active && !helpers.activeCameraSubscribers(options)}
                                setPreloadWaitingScreen={setPreloadWaitingScreen}
                            />
                        )}
                    </div>
                </div>
                <div className={helpers.controlClass(options)} id="controls">
                    <div className={helpers.localAudioClass(options)} onClick={toggleLocalAudio} />
                    <div className={helpers.localVideoClass(options)} onClick={toggleLocalVideo} />
                    <div className={helpers.localCallClass(options)} onClick={onPressEndCallButton} />
                    {helpers.activeCameraSubscribers(options) && options.active && !isOnViewChatButton()
                        ? <div className={helpers.localChatButtonClass(options)} onClick={toggleChat} />
                        : ""
                    }
                    <div className={helpers.localChangeCameraClass(options)} onClick={toggleCamera}>
                        <IonIcon className="changeCameraIcon" icon={cameraReverseOutline} size="small" />
                    </div>
                </div>
            </PageWrapper>;
    
    return helpers.renderSelector(
        !localState,
        spinner, 
        callError,
        error(t("somethingWrong"), t("startAgain"), onClickRestart),
        storeIsClosed,
        error(closedStoreMessage, t("wantToBeContacted"), generateOfflineIssue, "twoButtons", t("startAgain"), onClickChooseAnother),
        isFirstWaitingPeriod,
        helpers.activeCameraSubscribers(options),
        error(localState.data.WaitingBody, t("wantToBeContacted"), generateOfflineIssue, "twoButtons",  t("startAgain"), onClickRestart),
        call,
    );
}
export default OpentokRender;