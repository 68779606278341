import React from 'react'
import Countdown from 'react-countdown';
import moment from 'moment';
import "./CallUrl.scss";
import t from '../utils/translations';

const CallUrl = ({limitTime}) => {
   
    const dateNow = moment(new Date());
    const diff = -dateNow.diff(limitTime);  

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (minutes === 0 && seconds === 1) window.location.reload();
          return (
            <div className='callUrlContainer'>
                <h3>{t("waitingCallUrl")} </h3>
              {`${hours}:`}
              {minutes < 10 ? `0${minutes}:` : `${minutes}:`}
              {seconds < 10 ? `0${seconds} ` : `${seconds} `}
              { hours >= 1  ? `${t("hour")}`: minutes === 1 ?  `${t("minute")}` :  `${t("minutes")}`}
            </div>
          );
        }
   

  return (
   
    <Countdown date={Date.now() + diff} renderer={renderer}  />
  )
}

export default CallUrl