import React from "react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import "./Error.scss";

const Error = ({ errorTitle, errorMessage, secondErrorMessage }) => (
	<div className="applyCustomBoxStyle marginToHeader" style={{ width: "auto" }}>
		<div className="smallMarginVertical useFlex useFlexCenter columnContainerWithFlex">
			<div className="imageAndTextContainer">
				<IonIcon className="errorIcon" icon={closeOutline} size="large" />
				<h1 className="errorColor">{errorTitle}</h1>
				<h2 className="centerText">{errorMessage}</h2>
				{secondErrorMessage && <h2 className="centerText negativeTop">{secondErrorMessage}</h2>}
			</div>
		</div>
	</div>
);

export default Error;