import { IonImg } from "@ionic/react";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Input from "../components/Input";
import Selector from "../components/Selector";
import SelectorItem from "../components/SelectorItem";
import Radio from "../components/Radio";
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import Spinner from "../components/Spinner";
import PageWrapper from "../components/PageWrapper";
import SearchableSelector from "../components/SearchableSelector";

import t from "../utils/translations";
import { createAnswers } from "../services/api";
import { setWrapperTop } from "../utils/helpers";
import { landingOnLoad } from "../utils/landingLoad";

const Questions = ({ location, history }) => {
	const [loading, setLoading] = useState(true);
	const [localState,] = useState(location.state);
	const [answers, setAnswer] = useState({});
	const [validations, setValidations] = useState([]);
	console.log(validations)
	console.log(answers)

	useEffect(() => {
		landingOnLoad(localState, history, onSuccessLoad, () => setLoading(true));
	}, [localState, history]);

	const onSuccessLoad = () => {
		setLoading(false);

		const validationsArray = [];
		localState.data.Questions.map(question => question.Required && validationsArray.push(question.Guid));
		setValidations(validationsArray);
	};

	const createItem = option => (
		<SelectorItem
			key={option.Guid}
			name={option.Name}
			value={option.Guid}
		/>
	);

	const createSearchableSelectorOptions = options => options.map(option => {
		return { label: option.Name, value: option.Guid };
	});

	const componentMapper = question => {
		switch (question.IdQuestionType) {
			case 1:
				return (
					<Input
						key={question.Guid}
						placeholder={question.Name}
						required={question.Required}
						value={answers[question.Guid]}
						onChange={e => setAnswer({ ...answers, [question.Guid]: e.detail.value })}
					/>
				);

			case 2:
				return (
					<Selector
						key={question.Guid}
						guid={question.Guid}
						multiple={true}
						placeholder={question.Name}
						required={question.Required}
						value={answers[question.Guid]}
						onChange={e => setAnswer({ ...answers, [question.Guid]: e.detail.value })}
					>
						{question.question_option.map(option => createItem(option))}
					</Selector>
				);

			case 3: //deshabilitada
				return (
					<Selector
						key={question.Guid}
						guid={question.Guid}
						placeholder={question.Name}
						required={question.Required}
						value={answers[question.Guid]}
						onChange={e => setAnswer({ ...answers, [question.Guid]: e.detail.value })}
					>
						{question.question_option.map(option => createItem(option))}
					</Selector>
				);
			// 	return (
			// 		<Checkbox 
			//			key={question.Guid}
			//			className="smallMarginHorizontal"
			// 			checked={answers[question.Guid]}
			// 			required={question.Required} //TODO check porque no tiene required!!
			// 			title={question.Name}
			// 			value={answers[question.Guid]}
			// 			onChange={e => setAnswer({...answers, [question.Guid]: e.detail.checked })}
			// 		/>
			// 	);
			case 4:
				return (
					<Radio
						key={question.Guid}
						elems={question.question_option}
						required={question.Required}
						title={question.Name}
						value={answers[question.Guid]}
						onChange={e => setAnswer({ ...answers, [question.Guid]: e.detail.value })}
					/>
				);
			case 5:
				return (
					<TextArea
						key={question.Guid}
						className="questionTextArea"
						placeholder={question.Name}
						required={question.Required}
						value={answers[question.Guid]}
						onChange={e => setAnswer({ ...answers, [question.Guid]: e.detail.value })}
					/>
				);
			case 7:
				return (
					<SearchableSelector
						key={question.Guid}
						className="applyCustomBoxStyle"
						options={createSearchableSelectorOptions(question.question_option)}
						placeholder={t("chooseTimeRange")}
						required={question.Required}
						title={question.Name}
						value={answers[question.Guid]}
						onChange={selected => setAnswer({ ...answers, [question.Guid]: selected })}
					/>
				);
			default:
				return;
		}
	};

	const sendAnswers = (answersToSend, idIssue) => {
		const answersSumbit = { Answers: answersToSend, IdIssue: idIssue }
		createAnswers(answersSumbit);
	};

	const handleSubmit = () => {
		setLoading(true);
		const arrayOfAnswers = [];
		for (const answer in answers) {
			if (Array.isArray(answers[answer])) {
				answers[answer].map(value => value && arrayOfAnswers.push({
					IdQuestion: answer,
					Value: value
				}));
			}
			else if (typeof answers[answer] === "object") {
				answers[answer] && arrayOfAnswers.push({
					IdQuestion: answer,
					Value: answers[answer].value
				});
			} else {
				answers[answer] && arrayOfAnswers.push({
					IdQuestion: answer,
					Value: answers[answer]
				});
			}
		};

		const { submit, routes } = localState;
		sendAnswers(arrayOfAnswers, submit.IdIssue);

		const nextRoute = routes.shift();
		submit.Answers = arrayOfAnswers;

		setWrapperTop(false, true);

		history.push({
			pathname: nextRoute,
			state: { ...localState, submit, routes }
		});
	};

	const fieldsValidation = () => validations.map(validation => !!answers[validation]).includes(false);

	return loading ? <Spinner /> : (
		<PageWrapper showFooter={localState.data.ShowFooter} showHeader={true} localState={localState}>
			{localState.data.Icon && (
				<div className="centerText marginToHeader">
					<IonImg className="smileIcon" src={localState.data.Icon} />
				</div>
			)}
			<h2>{t("answerQuestions")}</h2>
			<div className="smallMarginHorizontal marginToHeader columnContainerWithFlex">
				{localState.data.Questions.map(question => componentMapper(question))}
				<Button className="alignSelfCenter" disabled={fieldsValidation()} size={"large"} onClick={handleSubmit}>
					<FontAwesomeIcon className="arrowIconAdjust" icon={faChevronRight} size={"xs"} />
					{t("continue")}
				</Button>
			</div>
		</PageWrapper>
	);
};

export default Questions;
