import moment from "moment";
import React, { useEffect, useState} from "react";
import { URL_REGEX } from "../../../constants/validations";
import { sendChat } from "../../../utils/firebase";
import { classByAgent, isMobileAgent } from "../../../utils/helpers";
import { getKey } from "../../../utils/localStorage";
import t from "../../../utils/translations";
import "./AntMediaChat.css";
import { sendMessageIcon,  attachmentIcon, closeComponent } from "./AntMediaIcons";

const AntMediaChat = ({ open, showChat, name, lastName, logo, allChats, openLink, customShareLabel, setShowFiles, showFiles,AllowFileShare, colorPrimary}) => {
    const [newChat, setNewChat] = useState(null);

    useEffect(()=>{
        scroller()
    },[allChats])

    const scroller = () => setTimeout(() => document.getElementById("chatScroll") &&
  document.getElementById("chatScroll").scrollTo(0, document.getElementById("chatScroll").scrollHeight), 500);

    const chatsWithNewMessage = () => {
        const newChatList = allChats;
        newChatList.push({
            "author": name,
            "message": newChat,
            "created": moment().valueOf()
        });
        return newChatList;
      };

    const sendMessage = () => {
        if (newChat !== "") {
          sendChat(getKey("issueId"), chatsWithNewMessage());
          setNewChat("");
        } else setNewChat("");
      };

    const userDisplayPic = () => {
        if (name && !lastName) return <div className="avatar"> <p translate="no" className="avatarName">{`${name[0]}`}</p>  </div>
        if (name && lastName) return <div className="avatar"> <p  translate="no" className="avatarName">{`${name[0]}${lastName[0]}`}</p></div>
        if (!name && !lastName) return <img src="\img\noPic.png" className="avatar"/>
    };

    const clientChatStructure = chat => (
        <div translate="no" className="clientInboxContainer">
            <div translate="no" className="clientInbox"> {chat.message} </div>
            {userDisplayPic()}
        </div> 
    );
     
    const operatorChatStructure = chat => (
        <div translate="no" className="operatorInboxContainer">
           {/*  <img src={logo} className="avatar" style={{ backgroundColor: "transparent" }}/> */}
            <div className="avatar" style={{ backgroundColor: colorPrimary }}> <p className="avatarNameOperator">{`OP`}</p>  </div>
            <div className="operatorInbox"> {verifyUrl(chat.message)} </div>
        </div>
    );

    const verifyUrl = message => message.split(" ").filter(word => word !== "").map(word => {
        if (URL_REGEX(word)) return <a className="chatLink" onClick={() => openLink(word)}> {customShareLabel || t("openLink")} </a>;
        else return `${word} `;
    });


    
    const sizeIcon = isMobileAgent() ? 25 : 20

    return (
        <div className={classByAgent("amChatContainer")} id="chatContainer" >
            <div className={classByAgent("amChatContainerInner")} style={{ marginTop: open ? "0px" : "100%"}}>

                <div className="amChatClose"  onClick={showChat}> 
                {closeComponent("#6D6D6D", "20px", "20px")}
                 {/*    <img src="https://icongr.am/feather/x.svg?size=20px&color=6D6D6D" />  */}
                </div>
                <div className="amInboxScroll" id ="chatScroll">
                    {allChats.map(chat => chat.author === "operator" 
                        ? operatorChatStructure(chat)
                        : clientChatStructure(chat)
                    )}
                </div>
                <div className="amSenderContainer">
                    <input id="inputChat" autoComplete="off" onKeyUp={e => e.keyCode === 13 && sendMessage()} style={{ height: isMobileAgent() ? "40px" : "30px"}}className={AllowFileShare ? "amInputSenderAllowFile" : "amInputSender" } value={newChat} placeholder="Escribe aqui ..." onChange={e => setNewChat(e.target.value)}/>
                            {
                                 AllowFileShare && 
                                 <div className={classByAgent("iconChatContainer")}  onClick={()=>setShowFiles(true)}>
                                    {attachmentIcon("white",  `${sizeIcon}px`, `${sizeIcon}px`)}
                                 </div> 
                            }
                             
                
                    <div className={classByAgent("iconChatContainer")} onClick={sendMessage}>
                    {/* <img  src={`https://icongr.am/feather/send.svg?size=${sizeIcon}&color=FFFFFF`} />  */}
                    {sendMessageIcon("white",  `${sizeIcon}px`, `${sizeIcon}px`)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AntMediaChat;