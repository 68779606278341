import React from "react";
import { inIframe, isMobileAgent } from "../../../utils/helpers";
import "./AntMedia.css";

const AntMediaRemoteVideo = ({ showRemoteVideo, rotate, screenAdjust }) => {
    const isDesktop = window.screen.width > 800 && !inIframe() && !isMobileAgent();
   const heightVideo = (rotate && screenAdjust) ?  "80vh" : screenAdjust ? "40vh" : "100vh"
   const topVideo = (rotate && screenAdjust) ?  "0px" : screenAdjust ? "25vh" : "0px"
   const objetFitVideo = (rotate && screenAdjust) ? "fill" :"cover"
   const objectFitVideoDesktop = screenAdjust ? "fill": "fill"

    const styleMobile = ({
        position: "fixed",
        height: heightVideo,
        width: "100vw",
        left: "0px",
        top: topVideo,
        display: !showRemoteVideo ? "none" : "block", 
        resize: "none",
        background: "grey",
        transform:  screenAdjust && "rotate(0deg)",  
        objectFit: objetFitVideo , 
    });
        
    const styleDesktop = ({
        display: !showRemoteVideo ? "none" : "block", 
        position: "absolute",
        objectFit: objectFitVideoDesktop,
        background: "grey", 
        transform:  screenAdjust && "rotate(0deg)", 
    });

    return isDesktop 
    ? <div className="remoteVideoDesktopContainer"> <video className="remoteVideoDesktop" id="remoteVideo" muted playsInline autoPlay style={styleDesktop}/> </div>
    : <video id="remoteVideo" muted playsInline autoPlay style={styleMobile} />
    
};

export default AntMediaRemoteVideo;