import { inIframe, isMobileAgent } from "../../../utils/helpers";
import t from "../../../utils/translations";
const isDesktop = window.screen.width > 800 && !inIframe() && !isMobileAgent();

// UTILS 
export const sharingScreen = options => options.meta && !!options.meta.publisher.screen ? "meta" : null;
export const viewingSharedScreen = options => options.meta && options.meta.subscriber.screen ? "meta" : null;
export const activeCameraSubscribers = options => options.meta && options.meta.subscriber.camera ? "meta" : 0;
export const activeCameraSubscribersGt2 = options => activeCameraSubscribers(options) > 2;
export const activeCameraSubscribersOdd = options => activeCameraSubscribers(options) % 2;
export const screenshareActive = options => viewingSharedScreen(options) || sharingScreen(options);
export const inWaitingScreen = options => options.active && activeCameraSubscribers(options) < 1;


// CLASSES -forceFixedPos
export const controlClass = options => ["App-control-container", !options.active ? "hidden" : null, !inWaitingScreen(options) && !isDesktop && "forceFixedPos"].join(" ");
export const localAudioClass = options => ["ots-video-control circle audio", !options.active ? "hidden" : null, !options.localAudioEnabled ? "muted" : null].join(" ");
export const localVideoClass = options => ["ots-video-control circle video", !options.active ? "hidden" : null, !options.localVideoEnabled ? "muted" : null].join(" ");
export const localCallClass = options => ["ots-video-control circle end-call", !options.active ? "hidden" : null].join(" ");
export const localChatButtonClass = () => ["ots-video-control circle text-chat"].join(" ");
export const localChatClass = options => ["App-chat-container", !options.active && !options.connected ? "ots-hidden" : null, !options.showChat ? "ots-hidden disabled" : "enabled"].join(" ");
export const localChangeCameraClass = options => ["ots-video-control circle centerContent", !options.active ? "hidden" : null].join(" ");
export const cameraPublisherClass = options => [inWaitingScreen(options) ? "video-container small" : "video-container small-Oncall", !options.active || screenshareActive(options) ? "hidden" : null].join(" ");
export const screenPublisherClass = options => ["video-container", (!options.active || !sharingScreen(options)) ? "hidden" : null].join(" ");
export const cameraSubscriberClass = options => ["video-container videoFullHeight", (!options.active || !activeCameraSubscribers(options)) ? "hidden" : null,
    (activeCameraSubscribersGt2(options) && !screenshareActive(options)) ? "active-gt2" : null,
    (activeCameraSubscribersOdd(options) && !screenshareActive(options)) ? "active-odd" : null,
    screenshareActive(options) ? "hidden" : null
    ].join(" ");
export const screenSubscriberClass = options => ["video-container screenSharing", (!viewingSharedScreen(options) || !options.active) ? "hidden" : null].join(" ");


// BUTTONS
export const videoCallButtons = (cancelHandler, okHandler) => [
    { text: t("cancel"), role: "cancel", cssClass: "secondary", handler: () => cancelHandler() },
    { text: t("ok"), handler: () => okHandler() }
];
export const stillThereButton = (noHandler, yesHandler) => [
    { text: t("no"), handler: () => noHandler() },
    { text: t("yes"), handler: () => yesHandler() }
];
export const notFoundCameraButton = okHandler => [
    { text: t("ok"), handler: () => okHandler() },
];
export const newChattMessageButton = (dontOpenHandler, openHandler) => [
    { text: t("dontOpen"), handler: () => dontOpenHandler() },
    { text: t("openChat"), handler: () => openHandler() }
];

// RENDER SELECTOR
export const renderSelector = (
    loading,
    inLoading, 
    error,
    inError,
    storeIsClosed,
    inClosedStore,
    isFirstWaitingPeriod,
    activeCameraSubscribers,
    inNotFirstPerAndCamSubs,
    inSuccessCall,
) => {
    if (loading) return inLoading
    else if (error) return inError //Error en opentok creando conexion, o error de api iniciando el issue.
    else if (storeIsClosed) return inClosedStore
    else if (!isFirstWaitingPeriod && !activeCameraSubscribers) return inNotFirstPerAndCamSubs
    else return inSuccessCall
};