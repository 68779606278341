import React from "react";
import { IonFab, IonFabButton } from "@ionic/react";

import "./Fab.scss";

const Fab = ({ className, hide, horizontal, text, vertical, onClick, closeButton, handleClose }) => (
	<>
		<IonFab
			horizontal={horizontal}
			vertical={vertical}
		>
			<IonFabButton
				className={[className, hide && "hide"].join(" ")}
				onClick={onClick}
			>
				{text}
			</IonFabButton>
		</IonFab>
		{ closeButton && 
			(
					<IonFab
					horizontal={horizontal}
					vertical={vertical}
				>
					<IonFabButton
						className={["closeFab", hide && "hide"].join(" ")}
						onClick={handleClose}
					>
						X
					</IonFabButton>
				</IonFab>
			)
		}
	</>
);

export default Fab;