import React, { useState, useEffect } from "react";

import t, { registerTranslations } from "../../utils/translations";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import Spinner from "../../components/Spinner";
import PageWrapper from "../../components/PageWrapper";
import Error from "../../components/Error";
import PhoneInput from "../../components/PhoneInput";
import AntMediaAlert from "../Videocall/AntMedia/AntMediaAlert";
import { ReCaptcha } from 'react-recaptcha-v3'
import moment from "moment";

import { loadReCaptcha } from 'react-recaptcha-v3'
import { setOnLocalStorageAndCSS, setCssColor, hexToHSL } from "../../utils/colors";
import { deleteKey, setKey, getKey } from "../../utils/localStorage";
import { customerSave, createIssue, createAnswers, customerValidate, cancelIssue, createStore, validateHasBooking, modifyHasBooking } from "../../services/api";
import { getRoutes, EMAIL_REGEXP, DNI_REGEXP } from "../../constants/validations";
import { isIOS, isObjectEmpty, phoneChecker, checkForReload, sendEvent, addFeedbacksAnalytics } from "../../utils/helpers";
import { getBrowser } from "../../utils/getBrowser";
import { QuestionGenerator } from "../../components/QuestionGenerator";
import { sendPixelTrack, fbVerification } from "../../utils/analytics";
import { createChatStructure, createFileStructure } from "../../utils/firebase";
import CallUrl from "../../components/CallUrl";
import momentTimezone from "moment-timezone"
import momentTZ from 'moment-timezone'



const Home = ({ history, match, data }) => {
	const [loading, setLoading] = useState(true);
	const [submit, setSubmit] = useState({});
	const [localState] = useState({ data: data });
	const [checkedTyc, setCheckedTyc] = useState(false);
	const [error, setError] = useState(false);
	const [errorUrl, setErrorUrl] = useState(null)
	const [questions, setQuestions] = useState([])
	const [answers, setAnswer] = useState([])
	const [validations, setValidations] = useState([]);
	const [validPhone, setValidPhone] = useState(false)
	const [showQuestions, setshowQuestions] = useState(true)
	const [recaptcha, setRecaptcha] = useState({
		reCaptcha1: true,
		reCaptcha2: false,
		recaptcha1Token: null,
		recaptcha2Token: null,
	});
	const [showButton, setShowButton] = useState(true)

	const [limitTimes, setLimitTimes] = useState(true)
	const [bookingAttended, setBookingAttended] = useState(false)
	const [errorBooking, setErrorBokking] = useState(null)
	const [messageErrorBooking, setMessageErrorBooking]=useState (null)
	const [messageBooking, setMessageBokking] = useState(null)
	const [dataHasBooking, setDataHasBooking] = useState(null)
	const [localHourBooking, setLocalHourBooking] = useState(null)

	let desktop = window.screen.width > 800;

	const params = (new URL(document.location)).searchParams;
	const IdAppointment = params.get('appointment_id')
	const Guid = getKey("guid");

	const analyticsPixel = localState.data.AnalyticsPixel

	// call for URL
	const IdIssueParent = params.get("id")
	const customer_id = params.get('customer_id')
	const IdOperator = params.get("op")
	const limitTimeUrl = params.get("ttl")
	const store_id = params.get("store_id")
    const bookingId = params.get("booking") || getKey("bookingId")
	bookingId && setKey("bookingId", bookingId)


	const validate = () => validateHasBooking(bookingId)
	const {BookingSetting}=data 

	const [timeStartBooking, setTimestartBooking] = useState(BookingSetting ? BookingSetting.MinutesBefore : 15)
	const [outTimeFinishBooking, setTimeFinishBooking]=useState(BookingSetting ? BookingSetting.MinutesAfter : 15)
	const [customeQuestion, setCustomeQuestion] = useState ()
	

	useEffect(() => {
		if (data.OnlyByBooking && (!bookingId || !getKey("bookingId"))) {
			setErrorBokking("notBooking")
			setMessageErrorBooking(BookingSetting.HasNoBookingMessage ? BookingSetting.HasNoBookingMessage  : t("notBooking"))
		}
	}, [data.OnlyByBooking])

	const linkMakeBooking = BookingSetting && BookingSetting.RedirectionUrl

	const makeReservation = () => {
		window.open(linkMakeBooking)
	}

	const miZonaHoraria = momentTZ.tz.guess();
	const fecha = momentTZ.tz(dataHasBooking?.Time, dataHasBooking?.Timezone);
	const fechaEnMiZonaHoraria = fecha.clone().tz(miZonaHoraria)
	
	

	useEffect(() => {
		data.OnlyByBooking && (bookingId || getKey("bookingId")) && validate().then((res) => {
			const { response, success } = res
			if (response.Status === 3) setBookingAttended(true)
			if (success) {
				setDataHasBooking(res.response.Booking)
				setLocalHourBooking(res.response.Now)
				setSubmit({
					Name: response.Booking.FirstName,
					Lastname: response.Booking.LastName,
					Phone: response.Booking.Phone,
					Email: response.Booking.Email
				})
			} else {
					setTimeout(()=>{
						setErrorBokking("notBooking")
					},300)
					setMessageErrorBooking(BookingSetting.HasNoBookingMessage ? BookingSetting.HasNoBookingMessage  : t("notBooking"))
			
			}
		})
	}, []) 


	const startDateBooking = moment(dataHasBooking?.Time.replace(" ", "T"))
	const finishDateBooking = moment(dataHasBooking?.EndTime.replace(" ", "T"))
	const hourTimezoneLanding = moment(localHourBooking?.replace(" ", "T"))

	const hourTimezoneLandingModified = hourTimezoneLanding.clone().format()
	const startDateBookingModified = startDateBooking.clone().format()
	const finishDateBookingModified = finishDateBooking.clone().format()

	const diferenciaComienzo = moment(hourTimezoneLandingModified).diff(startDateBookingModified, 'minutes')
	const diferenciaFinalizacion = moment(hourTimezoneLandingModified).diff(finishDateBookingModified, 'minutes')

	useEffect(() => {

		if (dataHasBooking && localHourBooking && BookingSetting) {
			if (diferenciaComienzo <  -timeStartBooking) {
				setMessageBokking("beforeBookingTime")
				setMessageErrorBooking(BookingSetting.BeforeJoiningMessage ? BookingSetting.BeforeJoiningMessage : t("beforeBookingTime"))
			}
			if (diferenciaFinalizacion > outTimeFinishBooking) {
				setErrorBokking("outTimeFinishBooking")
				setMessageErrorBooking(BookingSetting.ReservationExpiredMessage ? BookingSetting.ReservationExpiredMessage : t("outTimeFinishBooking"))
			}
		}
	}, [dataHasBooking, localHourBooking, BookingSetting]);  


	const limitTime = new Date(limitTimeUrl * 1000)
	const timeTicketUrl = date => moment(data.time).isBefore(date)

	const callForUrl = IdIssueParent && IdOperator && limitTimeUrl && store_id ? true : false

	useEffect(() => {
		if ((limitTimeUrl && !limitTimes)) {
			setLimitTimes(false)
			setErrorUrl("expiredUrl")
		}
	}, [limitTimes])

	const newRequeriment = () => history.push(getKey("path"))

	const validateCustomer = () => {
		if (customer_id && timeTicketUrl(limitTime))
			customerValidate({ IdCustomer: customer_id, IdOrganization: data.IdOrganization, })
				.then(({ success, response }) => {
					if (success) {
						setSubmit({ ...submit, Name: response.Name })
						setshowQuestions(false);
						setKey("IdCustomer", customer_id);
					}
				});
	};

	const unload = () => {
		const nodeBadge = document.querySelector('.grecaptcha-badge');
		if (nodeBadge) {
			document.body.removeChild(nodeBadge.parentNode);
		}

		const scriptSelector = 'script[src=\'https://www.google.com/recaptcha/api.js?render=' +
			"6LcR-h0bAAAAACnB-8pqQByYmkwULXJZA6R_5YA-" + '\']';
		const script = document.querySelector(scriptSelector);
		if (script) script.remove();
	};

	useEffect(() => {
		deleteKey('Guid');
		checkForReload();
		validateCustomer();
		if (isIOS() && getBrowser().browserName === "Chrome") history.push(`/${match.params.query}/browserChange`);
		localStorage.clear();
		setLoading(true);
		fbVerification(data.FacebookTag);
		setOnLocalStorageAndCSS("--primary-color", data.ColorPrimary);
		setOnLocalStorageAndCSS("--secondary-color", data.ColorSecondary);
		setLimitTimes(timeTicketUrl(limitTime))
		analyticsPixel && addFeedbacksAnalytics(analyticsPixel);
		setCssColor("--primary-color-darken", hexToHSL(data.ColorPrimary, -8));
		setCssColor("--secondary-color-lighten", hexToHSL(data.ColorSecondary, 50));
		setOnLocalStorageAndCSS("--logo", `url("${data.Logo}")`);
		setKey("logoNoti", data.Logo);
		if (data['Images'] && data['Images'].length > 0) {
			let result = data.Images.filter(img => img.Type == "Banner")
			if (result.length > 0) setOnLocalStorageAndCSS("--header-img", `url("${result[0].Image}")`)
			else setOnLocalStorageAndCSS("--header-img", null)
		} else setOnLocalStorageAndCSS("--header-img", null)
		setKey("images", JSON.stringify(data.Images))
		setKey("path", match.url);
		setQuestions(data.Questions);
		registerTranslations(data.Dictionary);
		loadReCaptcha("6LcR-h0bAAAAACnB-8pqQByYmkwULXJZA6R_5YA-");
		setLoading(false);
	}, [history, match.params.query, match.url]);

	useEffect(() => { }, [checkedTyc, validPhone, showQuestions, recaptcha]);

	const onCaptcha = value => {
		if (!recaptcha.recaptcha1Token)
			setRecaptcha({ ...recaptcha, reCaptcha1: false, recaptcha1Token: value, reCaptcha2: true });
		else setRecaptcha({ ...recaptcha, recaptcha2Token: value });
	};

	const tycElement = () => {
		const link = localState.data.TOSUrl
		if (link) {
			let arr = []
			arr = link.split('')
			arr.splice(2, 0, " style= margin-top:4px ")
			let result = arr.join('')
			return result
		}
	};

	const validateNumber = (countryCode, phone) => {
		const isValidPhone = phoneChecker(countryCode, phone);
		if (isValidPhone) setValidPhone(true)
		else setValidPhone(false);
	};

	const setPhoneNumber = (phoneNumber, data) => {
		let phoneNum = phoneNumber;
		let rawPhone = phoneNumber.slice(data.dialCode.length);
		if (data.name === "Argentina") rawPhone = `9${rawPhone}`
		phoneNum = data.dialCode.concat(rawPhone);
		setSubmit({ ...submit, Phone: phoneNum });
		validateNumber(data.countryCode, rawPhone);
	};

	const errorComponent = (
		<PageWrapper showFooter={true} showHeader={true}>
			<Error errorMessage={t("errorOcurred")} errorTitle={t("error")} />
		</PageWrapper>
	);

	const submitQuestions = () => {
		const arrayOfAnswers = [];
		for (const answer in answers) {

			if (Array.isArray(answers[answer])) {
				answers[answer].map(value => value && arrayOfAnswers.push({
					IdQuestion: answer,
					Value: value
				}));
			}
			else if (typeof answers[answer] === "object") {
				answers[answer] && arrayOfAnswers.push({
					IdQuestion: answer,
					Value: answers[answer].value
				});
			} else {
				answers[answer] && arrayOfAnswers.push({
					IdQuestion: answer,
					Value: answers[answer]
				});
			}
		}
		return arrayOfAnswers
	};

	
	const newIssue = async (submit, response, appoinment_id, answers) => {
		const newCustomer = !customer_id ? response : customer_id;
		const operator_id = IdOperator && timeTicketUrl(limitTime) ? IdOperator : null
		const prevTicket = IdIssueParent && timeTicketUrl(limitTime) ? IdIssueParent : null
		createIssue({ ...submit, Customer: newCustomer, IdIssueParent: prevTicket, IdOperator: operator_id, IdAppointment, Captcha: recaptcha.recaptcha2Token })
			.then(issueResponse => {
				if (issueResponse.success) {
					sendEvent("Lead_Vivet", "Lead");
					 !bookingAttended && bookingId && modifyHasBooking(bookingId, issueResponse.response.IdIssue) 
					submit.IdIssue = issueResponse.response.Guid;
					submit.antMediaWS = issueResponse.response.Ws;
					setKey("issueId", issueResponse.response.IdIssue);
					setKey("issueGuid", issueResponse.response.Guid);
					setKey("ticketCreated", true);
					if (data.MergeQuestions && data.AllowQuestion && showQuestions) {
						const answersSumbit = { Answers: answers, IdIssue: issueResponse.response.Guid }
						createAnswers(answersSumbit).catch(() => setError(true));
					};
					createChatStructure(issueResponse.response.IdIssue, data.MessageChat);
					createFileStructure(issueResponse.response.IdIssue)
					if (callForUrl) {
						const store = { IdStore: store_id, IdDepartment: null, IdIssue: issueResponse.response.Guid }
						createStore(store);
					}
				} else setError(true);
			}).catch(() => setError(true));
	};


	const routesBO = getRoutes(localState.data, match.params.query)
	const resultRoutes = routesBO.findIndex(ruta => ruta.includes("/4"))
	const newRoutes = routesBO.slice(resultRoutes)

	const handleSubmit = event => {
		event && event.preventDefault();
		sendEvent("Click inicio - Aceptar");
		let answers = submitQuestions();
		const routes = callForUrl ? newRoutes : getRoutes(localState.data, match.params.query);
		const allRoutes = [...routes];
		const nextRoute = routes.shift();
		const config = {};

		if (callForUrl) {
			var submitModified = {
				...submit,
				IdOrganization: localState.data.IdOrganization,
				IdLanding: localState.data.IdLanding,
				IdStore: store_id
			};
		} else {
			var submitModified = {
				...submit,
				IdOrganization: localState.data.IdOrganization,
				IdLanding: localState.data.IdLanding,
			};
		}


		if (localState.data.Store && localState.data.Store.length === 1) {
			submitModified.IdStore = localState.data.Store[0].Guid;
			config.Block = localState.data.Store[0].Block;
			config.IsOpen = localState.data.Store[0].IsOpen;
		}

		if (localState.data.Store && localState.data.Store.length != 1 && callForUrl) {
			const selectedStore = localState.data.Store.filter(store => store.Guid === store_id)[0]
			config.Block = selectedStore.Block;
			config.IsOpen = selectedStore.IsOpen;
		}

		if (localState.data.Departments && localState.data.Departments.length === 1)
			submitModified.IdDepartment = localState.data.Departments[0].Guid;

		if (!customer_id) {
			submitModified.Captcha = recaptcha.recaptcha1Token;
			customerSave(submitModified)
				.then(({ success, response }) => {
					if (success) {
						setKey('Guid', response)
						newIssue(submitModified, response, IdAppointment, answers).then(() => nextPage());
					};
				}).catch(() => setError(true));
		} else newIssue(submitModified, false, IdAppointment, answers).then(() => nextPage());

		const nextPage = () => history.push({
			pathname: nextRoute,
			state: {
				data: localState.data,
				config,
				routes,
				allRoutes,
				submit: submitModified,
				initialSubmit: { ...submitModified },
			}
		});

		unload();
	};


	const loadQuestions = () => {
		const validationsArray = [];
		localState.data.Questions.map(question => question.Required && validationsArray.push(question.Guid));
		setValidations(validationsArray);
	}

	useEffect(() => {
		localState.data.Questions && localState.data.MergeQuestions && loadQuestions()
	}, [])

	const fieldsValidation = () => showQuestions ? Object.values(submit).includes(undefined)
		|| Object.values(submit).includes("")
		|| (localState.data.TOSUrl ? localState.data.TOSUrl && !checkedTyc : false)
		|| (localState.data.AllowRegister && localState.data.SetPhone && !validPhone)
		|| validations.map(validation => !!answers[validation]).includes(false) :
		(localState.data.TOSUrl ? localState.data.TOSUrl && !checkedTyc : false)



	const tycValidation = () => !isObjectEmpty(localState) && localState.data.TOSUrl && localState.data.TOSUrl.includes('<p>');

	const disabledButton = () => {
		sendPixelTrack("segurosmundial", "ViewContent")
		setShowButton(false)
	}
	 const inputStyle ={ 
			width: '1px',
			height: '1px',
			border: 'none',
			margin: '-1px',
			padding: '0',
			overflow: 'hidden',
			position: 'absolute',
			clip: 'rect(0 0 0 0)',
			clipPath: 'inset(50%)',
		    whiteSpace: 'nowrap',
	 }

	return loading ? <Spinner />
		: error ? errorComponent : (
			<PageWrapper showFooter={false} showHeader={true} localState={localState}>
				<div className="smallMarginHorizontal">
					{!desktop && <h2>{localState.data.GreetingsTitle}</h2>}
					{localState.data.GreetingsBody && <h3 style={{ marginTop: desktop && "15px" }}>{!limitTimeUrl ? localState.data.GreetingsBody : t("titelCallUrl")}</h3>}
					{limitTimeUrl && limitTimes && <CallUrl limitTime={limitTime} />}
					<form className="inputWrapper columnContainerWithFlex" onSubmit={handleSubmit}>
						{localState.data.AllowRegister && localState.data.SetName && showQuestions && (
							<>
							<input  type="text" name="Name" onChange={e => setSubmit({ ...submit, Name: e.target.value })} 
							style={inputStyle}/>
							<Input
								required
								inputmode="text"
								name="Name"
								placeholder={t("enterName")}
								type="text"
								value={submit.Name}
								onChange={e => setSubmit({ ...submit, Name: e.detail.value })}
							/>
							</>
						)}
						{localState.data.AllowRegister && localState.data.SetLastName && showQuestions && (
							<>
							<input type="text" name="Lastname" onChange={e => setSubmit({ ...submit, Lastname: e.target.value })} style={inputStyle}/>
							<Input
								required
								inputmode="text"
								name="Lastname"
								placeholder={t("enterLastname")}
								value={submit.Lastname}
								onChange={e => setSubmit({ ...submit, Lastname: e.detail.value })}
								/>
								</>
						)}
						{localState.data.AllowRegister && localState.data.AllowCompany && showQuestions && (
							<>
							<input type="text" name="Company" onChange={e => setSubmit({ ...submit, Company: e.target.value })} style={inputStyle}/>
							<Input
								required
								inputmode="text"
								name="Company"
								placeholder={t("enterCompany")}
								value={submit.Company}
								onChange={e => setSubmit({ ...submit, Company: e.detail.value })}
							/>
							</>
						)}
						{(localState.data.IdIssueType.IdIssueType === 2 || (localState.data.AllowRegister && localState.data.SetEmail)) && showQuestions && (
							<>
							<input type="email" name="email" onChange={e => setSubmit({ ...submit, Email: e.target.value })} style={inputStyle}/>
							<Input
								required
								inputmode="email"
								pattern={EMAIL_REGEXP}
								placeholder={t("enterEmail")}
								value={submit.Email}
								onChange={e => setSubmit({ ...submit, Email: e.detail.value })}
							/>
							</>
						)}
						{localState.data.AllowRegister && localState.data.SetDNI && (
							<>
							<input className="newInput" name="Dni" onChange={e => setSubmit({ ...submit, Dni: e.target.value })} style={inputStyle}/>
							<Input
								required
								inputmode="numeric"
								maxlength={8}
								minlength={6}
								pattern={DNI_REGEXP}
								placeholder={t("enterDni")}
								value={submit.Dni}
								onChange={e => setSubmit({ ...submit, Dni: e.detail.value })}
							/>
							</>
						)}
						{localState.data.AllowRegister && localState.data.SetPhone && showQuestions && (
							<>
							<input name="Phone" onChange={e => setSubmit({ ...submit, Phone: e.target.value })} style={inputStyle}/>
							<PhoneInput
								placeholder={t("enterPhone")}
								value={submit.Phone}
								onChange={(phone, data) => setPhoneNumber(phone, data)}
								helpText={localState.data.PhoneHelp}
								initialCountry={localState.data.CountryCode}
							/>
							</>
						)}
						{data.MergeQuestions && data.AllowQuestion && questions && showQuestions && data.OnlyByBooking && dataHasBooking?.CampaingCode &&   (
							<QuestionGenerator questions={questions} setAnswer={(e) => setAnswer(e)} answers={answers} code ={dataHasBooking?.CampaingCode ? dataHasBooking?.CampaingCode : null} />
						)}
							{data.MergeQuestions && data.AllowQuestion && questions && showQuestions &&  !data.OnlyByBooking && (
							<QuestionGenerator questions={questions} setAnswer={(e) => setAnswer(e)} answers={answers} />
						)}

						<Button className="alignSelfCenter"
							onClick={() => disabledButton()}
							size={"large"}
							type="submit"
							disabled={fieldsValidation() || !showButton}
						>
							{localState.data.TOSUrl ? t("acceptAndContinue") : t("continue")}
						</Button>


						{tycValidation() && (
							<div style={desktop ? { width: "auto" } : null} className="disclaimer" >
								<Checkbox checked={checkedTyc} onChange={() => setCheckedTyc(!checkedTyc)} />
								<div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: tycElement() }} />
							</div>
						)}
						{recaptcha.reCaptcha1 && (
							<ReCaptcha
								sitekey="6LcR-h0bAAAAACnB-8pqQByYmkwULXJZA6R_5YA-"
								action='customer'
								verifyCallback={onCaptcha}
							/>
						)}
						{recaptcha.reCaptcha2 && (
							<ReCaptcha
								sitekey="6LcR-h0bAAAAACnB-8pqQByYmkwULXJZA6R_5YA-"
								action='customer'
								verifyCallback={onCaptcha}
							/>
						)}
					</form>
					{errorUrl && (<AntMediaAlert name={errorUrl} type="error" onClickLeft={newRequeriment} />)}
					 {errorBooking && (<AntMediaAlert name={errorBooking} type="alert" message={messageErrorBooking} onClickLeft={makeReservation} />)}
					{/* {errorBooking && (<AntMediaAlert name={errorBooking} type="error" onClickLeft={makeReservation} />)} */}
					{messageBooking && (<AntMediaAlert name={messageBooking} message={`${messageErrorBooking } ${t("bookingFor")}  ${fechaEnMiZonaHoraria.format(' DD/MM/YYYY, h:mm:ss a')}`} type="alert" onClickLeft={makeReservation} />)}
				</div>
			</PageWrapper>
		);
};

export default Home;

