import React from 'react'
import Input from './Input';
import Radio from './Radio';
import SearchableSelector from './SearchableSelector';
import Selector from './Selector';
import SelectorItem from './SelectorItem';
import TextArea from './TextArea';
import t from "../utils/translations";
import "./QuestionGenerator.scss"

export const QuestionGenerator = ({questions , setAnswer , answers, code}) => {
    
	const createItem = option => (
		<SelectorItem
			key={option.Guid}
			name={option.Name}
			value={option.Guid}
		/>
	);
    const createSearchableSelectorOptions = options => options.map(option => {
		return { label: option.Name, value: option.Guid };
	});
    const componentMapper = question => {
        switch (question.IdQuestionType) {
            case 1:
                return (
                    <Input
                        key={question.Guid}
                        placeholder={question.Name}
                        required={question.Required}
                        value={answers[question.Guid]}
                        onChange={e => setAnswer({ ...answers, [question.Guid]: code ? code :  e.detail.value })}
                        disabled={code}
                    />
                );

            case 2:
                return (
                    <Selector
                        key={question.Guid}
                        guid={question.Guid}
                        multiple={true}
                        placeholder={question.Name}
                        required={question.Required}
                        value={answers[question.Guid]}
                        onChange={e => setAnswer({ ...answers, [question.Guid]: e.detail.value })}
                    >
                        {question.question_option.map(option => createItem(option))}
                    </Selector>
                );

            case 3: //deshabilitada
                return (
                    <Selector
                        key={question.Guid}
                        guid={question.Guid}
                        placeholder={question.Name}
                        required={question.Required}
                        value={answers[question.Guid]}
                        onChange={e => setAnswer({ ...answers, [question.Guid]: e.detail.value })}
                    >
                        {question.question_option.map(option => createItem(option))}
                    </Selector>
                );
            // 	return (
            // 		<Checkbox 
            //			key={question.Guid}
            //			className="smallMarginHorizontal"
            // 			checked={answers[question.Guid]}
            // 			required={question.Required} //TODO check porque no tiene required!!
            // 			title={question.Name}
            // 			value={answers[question.Guid]}
            // 			onChange={e => setAnswer({...answers, [question.Guid]: e.detail.checked })}
            // 		/>
            // 	);
            case 4:
                return (
                    <Radio
                        key={question.Guid}
                        elems={question.question_option}
                        required={question.Required}
                        title={question.Name}
                        value={answers[question.Guid]}
                        onChange={e => setAnswer({ ...answers, [question.Guid]: e.detail.value })}
                    />
                );
            case 5:
                return (
                    <TextArea
                        key={question.Guid}
                        className="questionTextArea"
                        placeholder={question.Name}
                        required={question.Required}
                        value={answers[question.Guid]}
                        onChange={e => setAnswer({ ...answers, [question.Guid]: e.detail.value })}
                    />
                );
            case 7:
                return (
                    <SearchableSelector
                        key={question.Guid}
                        className="applyCustomBoxStyle"
                        options={createSearchableSelectorOptions(question.question_option)}
                        placeholder={t("chooseTimeRange")}
                        required={question.Required}
                        title={question.Name}
                        value={answers[question.Guid]}
                        onChange={selected => setAnswer({ ...answers, [question.Guid]: selected })}
                    />
                );
            default:
                return;
        }
    };
    return (
        questions.map(question => componentMapper(question))
    )
}
