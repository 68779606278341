import React from "react";

import VideocallError from "./VideocallError";

const BoundaryError = ({ children }) => {
    try {
        return children;
    } catch {
        return (
            <VideocallError 
                showFooter={true}
                showHeader={true}
                firstButtonText="Volver al inicio"
                errorTitle="Atencion"
                errorMessage="Algo no salio como esperabamos, disculpe las molestias ocasionadas."
    
            /> 
        );
    };
};

export default BoundaryError;