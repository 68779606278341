import fetch from "isomorphic-unfetch";
import axios from "axios" 
import encodeFormData from "./encodeFormData";
import { apiEndpoint } from "../utils/helpers";
import { data } from "@tensorflow/tfjs";

const HOST = apiEndpoint;
const GET = "GET";
const POST = "POST";

const api = (path, options) => {
	const { method, body, type, sendCookie } = {
		method: GET,
		body: {},
		...options,
	};
	const headers = { "Content-Type": "application/json" };
	if (type === "form") headers["Content-Type"] = "application/x-www-form-urlencoded";
	if (sendCookie) headers['Cookie'] = 'app_session=rd2o5ojg5p8n557bbu7umf6au16g76ec';
	if (method === GET)
		return fetch(`${HOST}${path}`, {
			method,
			headers,
		}).then(res => res.body ? res.json() : null);
	return fetch(`${HOST}${path}`, {
		method,
		headers,
		body: type === "form" ? encodeFormData(body) : JSON.stringify(body),
	}).then(res => res.body ? res.json() : null);
};

export const apiAxios = async (path, options) => {
	const { method, data } = options
	const headers = {
        "Content-Type": "multipart/form-data",
    };   
    const url = `${HOST}${path}`
    return await axios({url, data, method, headers})
    .then(response => {
        return response.data
    }).catch(e => {
        const { data } = e.response
        return data
    })
    
}; 

//Requests

export const getLanding = landing => api(`/live/landing/${landing}`, {
	method: GET
});

export const customerSave = body => api(`/customers/save`, {
	method: POST,
	body: { ...body }
})

export const customerValidate = body => api(`/customers/validate`, {
	method: POST,
	body: { ...body }
})
export const createIssue = body => api("/issues/issuecreate", {
	method: POST,
	body: { ...body }
});

export const createAnswers = body => api("/issues/issueanswer", {
	method: POST,
	body: { ...body }
});

export const createStore = body => api("/issues/issuestore", {
	method: POST,
	body: { ...body }
});

export const startIssue = body => api("/issues/issuestart", {
	method: POST,
	body: { ...body }
});

export const createIssueOffline = body => api("/issues/issueoffline", {
	method: POST,
	body: { ...body }
});

export const scoreIssue = body => api("/issues/issuescore", {
	method: POST,
	body: { ...body }
});

export const waitList = body => api(`/live/queue`, {
	method: POST,
	body: { ...body }
})

export const cancelIssue = body => api("/issues/issuecancel", {
	method: POST,
	body: { ...body }
});

export const checkStatus = body => api("/live/check_status", {
	method: POST,
	body: { ...body },
	type: "form"
});

export const finishIssue = body => api("/issues/issuefinish", {
	method: POST,
	body
});
export const finishIssueClient = body => api("/issues/issuecompletedclient", {
	method: POST,
	body
});

export const issueEvent = body => api("/issues/issueevent", {
	method: POST,
	body
});

export const issueDenied = body => api("/issues/issuedenied", {
	method: POST,
	body
});

export const createAppoinment = body => api("/appointments/create_appointment", {
	method: POST,
	body
});

export const available = body => api("/appointments/available", {
	method: POST,
	body: { ...body }
});


export const allowIframe = body => api("/live/allow_iframe", {
	method: POST,
	body: { ...body }
});

export const maxWait = body => api("/live/max_wait", {
	method: POST,
	body: { ...body }
});

export const getCities = body => api("/locations/get_cities", {
	method: POST,
	body: { ...body }
});

export const getCountries = body => api("/locations/get_countries", {
	method: POST,
	body: { ...body }
});

export const getProvinces = body => api("/locations/get_provinces", {
	method: POST,
	body: { ...body }
});

export const createLog = body => api("/live/share", {
	method: POST,
	body
});

export const issueQuit = (body) => api(`/issues/issuequit`, {
	method: POST,
	body: { ...body }
})

export const startRecording = body => api("/records/opentok_start", {
	method: POST,
	body: { ...body },
	type: "form",
	sendCookie: true,
});


export const stopRecording = body => api("/records/opentok_stop", {
	method: POST,
	body: { ...body },
	type: "form",
	sendCookie: true,
});

export const upLoadFiles =(body)=> apiAxios(`/issues/loadissuefiles`, {
	method: POST,
	data: body,
}) 

export const getLoadFiles = guid => api(`/issues/issuefiles/${guid}`, {
	method: GET,
});

export const connectionStatuts = body => api("/issues/status_connection", {
	method: POST,
	body: { ...body }
});

export const validateHasBooking = body => api("/bookings/hasBooking", {
	method: POST,
	body: {IdBooking: body} 
});

export const modifyHasBooking = (idBooking, idIssue) => api("/bookings/hasBookingByIdIssue", {
	method: POST,
	body: {IdBooking: idBooking, 
	       IdIssue:idIssue } 
});