import React from "react";
import { IonList, IonRadioGroup, IonLabel, IonItem, IonRadio } from "@ionic/react";

import "./Radio.scss";

const createRadio = (label, value) => (
	<IonItem key={label} className="radioItem">
		<IonLabel className="fontColor whiteSpaceNormal">{label}</IonLabel>
		<IonRadio value={value} />
	</IonItem>
);

const Radio = ({ elems, onChange, title, value }) => (
	<div>
		{ title &&
			<IonLabel className="title fontColor">
				{title}
			</IonLabel>
		}
		<IonList>
			<IonRadioGroup className= "margin" mode="md" value={value} onIonChange={onChange}>
				{elems.map(item => createRadio(item.Name, item.Guid))}
			</IonRadioGroup>
		</IonList>
	</div>
);

export default Radio;
