import React, { useEffect, useState } from "react";
import t from "../../../utils/translations";
import "./AntMediaAlerts.css";
import { classByAgent, inIframe, isMobileAgent } from "../../../utils/helpers";
import {alertCircleIcon, xCircleIcon} from "./AntMediaIcons"

// In case of just one button, use like it leftButton with onClickLeft.
const AntMediaAlert = ({ title, text, leftButton, rightButton, onClickLeft, onClickRight, type, name, message }) => {
    const [aTitle, setAtitle] = useState(title);
    const [aText, setAtext] = useState(text);
    const [aLeftButton, setALeftButton] = useState(leftButton);
    const [aRightButton, setARightButton] = useState(rightButton);
    const buttonsAlignment = () => aLeftButton && aRightButton ? "space-between" : "center";

    const checkRoute = ()=> window.location.pathname.includes("4")
    const isDesktop = window.screen.width > 800 && !inIframe() && !isMobileAgent();
    
    const isAlert = type === "alert"

  
    const detectAlert = () => {
        if (name === "stillThere") {
            setAtitle(t("attention"));
            setAtext(t("wantToKeepWaiting"));
            setARightButton(t("yes"));
            setALeftButton(t("no"));
        };

        if (name === "cancelCall") {
            setAtitle(t("confirmation"));
            setAtext(t(`${t("areYouSureYouWantTo")} ${t("endCall")} `));
            setARightButton(t("no"));
            setALeftButton(t("yes"));
        };
        if (name === "notBooking") {
            setAtitle(t("confirmation"));
            setAtext(message);
            setALeftButton(t("booking"));
        };
        if (name === "outTimeFinishBooking") {
            setAtitle(t("confirmation"));
            setAtext(message);
            setALeftButton(t("booking"));
        };
        if (name === "beforeBookingTime") {
            setAtitle(t("confirmation"));
            setAtext(t("beforeBookingTime"));
            setAtext(message)
           
        };
    };

    const detectError = () => {
        if (name === "closedStore") {
            setAtitle(t("confirmation"));
            setAtext( message ? message : t("closedStore"));
            setALeftButton(t("wantToBeContacted"));
        };
        if (name === "queueExceeded") {
            setAtitle(t("confirmation"));
            setAtext(t("queueExedeed"));
            setALeftButton(t("wantToBeContacted"));
        };
        if (name === "issueError") {
            setAtitle(t("confirmation"));
            setAtext(t("unknkowError"));
            setALeftButton(t("createNewRequirement"));
        };
        if (name === "notPermissions") {
            setAtitle(t("confirmation"));
            setAtext(t("contactMeOffline"));
            setARightButton(t("wantToBeContacted"));
            setALeftButton(t("createNewRequirement"));
        };

        if (name === "expiredUrl") {
            setAtitle(t("confirmation"));
            setAtext(t("expiredUrlText"));
            setALeftButton(t("createNewRequirement"));
        };
      
        if (name === "errorGeneral") {
            setAtitle(t("confirmation"));
            setAtext(t("somethingWrong"), t("recommendation"));
            setALeftButton(t("createNewRequirement"));
        };

    };

    const autoDetect = () => {
        if (type === "alert") detectAlert();
        else detectError();
    };

    useEffect(() => autoDetect(), [name]);

    return (
        <div className={classByAgent("AMalertContainer")} style={{paddingRight: isDesktop && checkRoute  && "41%"}}>
            <div className={classByAgent("AMalert")}>
                <div className={classByAgent("AMalertIcon")}>
                {isAlert ? alertCircleIcon("#ffcc00", "45px", "45px") : xCircleIcon("#F52E2",  "45px", "45px")}
                </div>
              {/*   <img style={{ margin: "auto" }} src={`https://icongr.am/feather/${isAlert ? "alert-circle" : "x-circle"}.svg?size=45&color=${isAlert ? "ffcc00" : "F52E2E"}`}/>  */}
                <h1> {aTitle} </h1>
                <p> {aText} </p>
                <div className="AMalertButtons" style={{ justifyContent: buttonsAlignment()}} >
                    {aLeftButton &&  <button onClick={onClickLeft}> {aLeftButton} </button>}
                    {aRightButton && <button onClick={onClickRight}> {aRightButton} </button>}
                </div>
            </div>
        </div>
    );
};

export default AntMediaAlert;