import { setCssColor } from "./colors";
import { getKey, setKey } from "./localStorage";

const redirectOnError = history => {
    const landingUsed = getKey("path");
    if (landingUsed) history.push({ 
        pathname: landingUsed 
    });
    else history.push({ 
        pathname: "/404" 
    });
};

const setStyleOnSuccessLoad = () => {
    const primaryColor = getKey("primary-color");
    const secondaryColor = getKey("secondary-color");
    const logo = getKey("logo");

    setCssColor("--primary-color", primaryColor);
    setCssColor("--secondary-color", secondaryColor);
    setCssColor("--logo", logo);
};

const actionOnSuccess = onSuccessLoad => {
    setStyleOnSuccessLoad();
    if (onSuccessLoad) onSuccessLoad();
};

const stepRefreshed = () => {
    var isStepRefreshed;
    if (window.location.pathname === getKey("currentPath")) isStepRefreshed = true;
    setKey("currentPath", window.location.pathname);
    return isStepRefreshed;
};

export const landingOnLoad = (currentState, history, onSuccessLoad, onPreLoad) => {
    if (onPreLoad) onPreLoad();

	if (!currentState || stepRefreshed()) redirectOnError(history);
	else actionOnSuccess(onSuccessLoad);
};
