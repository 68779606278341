import React from "react";
import { IonAlert } from "@ionic/react";

import "./Alert.scss";

const Alert = ({ buttons, header, isOpen, message, onDidDismiss }) => (
	<IonAlert
		buttons={buttons}
		header={header}
		isOpen={isOpen}
		message={message}
		mode={"md"}
		onDidDismiss={onDidDismiss}
	/>
);

export default Alert;
