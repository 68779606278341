import React from "react";
import { classByAgent } from "../../../utils/helpers";
import "./AntMedia.css";
import t from "../../../utils/translations";

const AntMediaIframe = ({ link, closeIframe }) => {
    return (
        <div className={classByAgent("iframeContainer")}>  
            <div className={classByAgent("iframeClose")} onClick={closeIframe}>
                 <span>{t("close")}</span>
                 </div>
            <iframe id={classByAgent("iframeLink")}
                title="Cubiq"
                width={window.innerWidth}
                height={window.innerHeight}
                src={link}
                style={{ background: "white" }}
            />
        </div>
    );
};

export default AntMediaIframe;