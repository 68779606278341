import "firebase/firestore";
import moment from "moment";
import firebase from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyAxxRy90Kv2Ol__QjLBZfgLun0AZuxgTSU",
  authDomain: "livesupport-af09e.firebaseapp.com",
  databaseURL: "https://livesupport-af09e-default-rtdb.firebaseio.com",
  projectId: "livesupport-af09e",
  storageBucket: "livesupport-af09e.appspot.com",
  messagingSenderId: "115989200974",
  appId: "1:115989200974:web:2b15ec086adb30a47c831f",
};

if (firebase.apps.length === 0) firebase.initializeApp(firebaseConfig);

export const database = firebase.firestore();

/* MINIMIZE & MAXIMIZE */
export const getDataByFirebase = (collection, document) => {
  return database
    .collection(collection)
    .doc(document)
    .get()
    .then(data => data.data())
};

/* CHAT */
export const createChatStructure = (issueGuid, message) => {
  const docId = issueGuid.toString();
  const welcomeChat= message &&  {
    author: "operator",
    message: message,
    created: moment().valueOf()
  };
  return database
    .collection("chats")
    .doc(docId)
    .set({
    "chats":   message &&   [welcomeChat]
    })
};

export const sendChat = (issueGuid, chats) => {
  const docId = issueGuid.toString();
  return database
    .collection("chats")
    .doc(docId)
    .update({
      "chats": chats
    })
};

export const getChat = issueGuid => {
  const docId = issueGuid.toString();
  return database
    .collection("chats")
    .doc(docId)
    .get()
};

export const chatListener = (issueGuid, callBack) => {
  const docId = issueGuid.toString();
  return database         
    .collection("chats")
    .doc(docId)
    .onSnapshot(callBack);
};

export const newNotification = (issueGuid, type, data) => {
  const docId = issueGuid.toString();
  return database
    .collection("notifications")
    .doc(docId)
    .set({
      "type": type,
      "data": data,
    });
};

export const notificationListener = (issueGuid, callBack) => {
  const docId = issueGuid.toString();
  return database         
  .collection("notifications")
  .doc(docId)
  .onSnapshot(callBack);
};

export const getNotification = issueGuid => {
  const docId = issueGuid.toString();
  return database
    .collection("notifications")
    .doc(docId)
    .get()
};

/* Files */
export const createFileStructure = (issueGuid) => {
  const docId = issueGuid.toString();
  const welcomeFile= {
    author: "client",
    message: "files",
    created: moment().valueOf()
  };
  return database
    .collection("files")
    .doc(docId)
    .set({
      "files": [welcomeFile]
    })
};

export const sendFile = (issueGuid, files) => {
  const docId = issueGuid.toString();
  return database
    .collection("files")
    .doc(docId)
    .update({
      "files": files
    })
};

export const getFile = issueGuid => {
  const docId = issueGuid.toString();
  return database
    .collection("files")
    .doc(docId)
    .get()
};

export const fileListener = (issueGuid, callBack) => {
  const docId = issueGuid.toString();
  return database         
    .collection("files")
    .doc(docId)
    .onSnapshot(callBack);
};

//Statistics

export const createStatisticsStructure = (issueGuid) => {
  const docId = issueGuid.toString();
  return database
    .collection("statistics")
    .doc(docId)
    .set({
      "SO": "", 
      "Navegador": "", 
      "statistics": []
    })
};

export const sendStatistics = (issueGuid, SO, browser,  data) => {
  const docId = issueGuid.toString();
  return database
    .collection("statistics")
    .doc(docId)
    .update({
      "SO": SO, 
      "Navegador": browser, 
      "statistics": firebase.firestore.FieldValue.arrayUnion(data)
    }).catch(()=> createStatisticsStructure(docId).then(()=>sendStatistics(docId, SO, browser,  data)))
};

export const createErrorStructure = (issueGuid) => {
  const docId = issueGuid.toString();
  return database
    .collection("error")
    .doc(docId)
    .set({
      "error": []
    })
};

export const sendError = (issueGuid, error, source) => {
  const docId = issueGuid.toString();
  return database
    .collection("error")
    .doc(docId)
    .update({
      "error": firebase.firestore.FieldValue.arrayUnion(
        {"error": error, 
			    "user": "client",
			   "source": source}
      )
    }).catch(()=> createErrorStructure(docId).then(()=>sendError(docId, error, source)))
};

export const createSignalClientStructure = (issueGuid) => {
  const docId = issueGuid.toString();
  return database
    .collection("signal")
    .doc(docId)
    .set({
      "signal": []
    })
};

export const sendSignalClient = (issueGuid, signal) => {
  const docId = issueGuid.toString();
  return database
    .collection("signal")
    .doc(docId)
    .update({
      "signal": firebase.firestore.FieldValue.arrayUnion(signal)
    }).catch(()=> createSignalClientStructure(docId).then(()=>sendSignalClient(docId, signal)))
};
export const getSignalCLient = issueGuid => {
  const docId = issueGuid.toString();
  return database
    .collection("signal")
    .doc(docId)
    .get()
};


export const createStatusClientStructure = (issueGuid) => {
  const docId = issueGuid.toString();
  return database
    .collection("status")
    .doc(docId)
    .set({
      "status": []
    })
};

export const sendStatusClient = (issueGuid, status) => {
  const docId = issueGuid.toString();
  return database
    .collection("status")
    .doc(docId)
    .update({
      "status": firebase.firestore.FieldValue.arrayUnion(status)
    }).catch(()=> createStatusClientStructure(docId).then(()=>sendStatusClient(docId, status)))
};

export const clientFinishCall = (issueGuid, time) => {
  const docId = issueGuid.toString();
  return database
    .collection("clientFinishCall")
    .doc(docId)
    .set({
      "action": "end call with button",
      "time": time
    });
};