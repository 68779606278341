import React from "react";
import "./PageWrapper.scss"
import { TwitterShareButton, FacebookShareButton, WhatsappShareButton } from "react-share";
import { logoFacebook, logoTwitter, logoWhatsapp } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { useHistory } from "react-router";
import { getKey } from "../utils/localStorage";
import { inIframe, isMobileAgent } from "../utils/helpers";
import t from "../utils/translations"

const DesktopWrapper = ({ children, data }) => {

	
    const backgroundImage = data.Images.find(type => type.Type === "DesktopBackground");
	const background = backgroundImage && backgroundImage.Image;
	const isDesktop = window.screen.width > 800 && !inIframe() && !isMobileAgent();
	const history = useHistory();
	const desktopStyle = {
		backgroundImage: background ? `url(${background})` : null,
		backgroundColor: !background ? data.ColorPrimary : null,
	};

	const URLToShare = data.CustomShareLink
		? data.CustomShareLink
		: `https://live.vivet.app/${window.location.pathname.split("/")[1]}`;

		const checkHeigth = ()=> window.location.pathname.includes("4") || window.location.pathname.includes("5")  ? "75vh" : null
		const checkWidth = ()=> window.location.pathname.includes("4")  || window.location.pathname.includes("5") ? "70vw" : null
		const checkDisplay = ()=> window.location.pathname.includes("4") || window.location.pathname.includes("5")  ? "none" : null 
		const isDecolar =  window.location.pathname.includes("ecolar") 
	

	return isDesktop ? (
		<div className="desktop-background" style={desktopStyle}>
			<div className="header-desktop">
				<div className= {!isDecolar ? "desktop-subheader" : "desktop-subheader-no-logo"} />
				{data.AllowShare &&
					<div className="social-container">
						<FacebookShareButton title={data.Name} url={URLToShare}>
							<IonIcon className="social-icon" icon={logoFacebook} style={{fontSize: "24px"}} />
						</FacebookShareButton>
						<WhatsappShareButton title={data.Name} url={URLToShare}>
							<IonIcon className="social-icon" icon={logoWhatsapp} style={{fontSize: "24px"}} />
						</WhatsappShareButton>
						<TwitterShareButton title={data.Name} url={URLToShare}>
							<IonIcon className="social-icon" icon={logoTwitter} style={{fontSize: "24px"}} />
						</TwitterShareButton>
					</div>
				}
			</div>
			<div className="app-desktop-container">
				<div className="text-desktop" style={{display:checkDisplay()}} > 
				{data.DropNote && <div className="text-desktop-sub"> {data.DropNote} </div>}
				<div className="text-desktop-imp"> {data.GreetingsTitle} </div>
				{data.BodyText && <div className="text-desktop-sub" dangerouslySetInnerHTML={{ __html: data.BodyText }} />}
				</div>
				<div className= "app-new-desktop-container"  style ={{width:checkWidth()}} >
				<div className="app-desktop" id="appscroll" style={{height:checkHeigth(), width:checkWidth() }}>
					<div className="app-desktop-inner" id="appscroll">
						{children}
					</div>
				</div>
				</div>
			</div>
			{/* <div 
				onClick={() => {
					const path = getKey("path");
					localStorage.clear();
					window.location.replace(path); 
				}} 
				className="desktop-cancel"
			> 
				{t("backToHome")} 
			</div> */}
		</div>
		) : <> {children} </>
};

export default DesktopWrapper;