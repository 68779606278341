import React, { useState, useEffect, useRef } from "react";
import AccCore from "opentok-accelerator-core";
import "opentok-solutions-css";

import t from "../../../utils/translations";
import Spinner from "../../../components/Spinner";
import OpentokRender from "./OpentokRender";

import { getKey, setKey, deleteKey } from "../../../utils/localStorage";
import {
  cancelIssue,
  checkStatus,
  finishIssue,
  allowIframe,
  maxWait,
  startIssue,
  createIssueOffline,
  startRecording,
  stopRecording
} from "../../../services/api";
import { connectionOptions } from "../../../services/connectionHelpers";
import { URL_REGEX } from "../../../constants/validations";
import { isIOS, sendEvent, sendAdwordsEvent} from "../../../utils/helpers";
import "./OpentokCall.scss";
import { toString } from "lodash";
import { database, getDataByFirebase } from "../../../utils/firebase";
import { landingOnLoad } from "../../../utils/landingLoad";


const OpenTok = ({ location, history, match}) => {
 
  const [localState] = useState(location.state);
  const [stopCheckingStatus, setStopCheckingStatus] = useState(false);
  const [options, setOptions] = useState({
    meta: null,
    localAudioEnabled: true,
    localVideoEnabled: true,
    connected: false,
  });
  const [alerts, setAlerts] = useState({
    endCall: false,
    stillThere: false,
    cameraNotFounded: false,
    newMessage: false,
  });
  const [localGuid, setLocalGuid] = useState(null);
  const [isIframLink, setIsIframeLink] = useState(false);
  const [link, setLink] = useState(null);
  const [showFab, setShowFab] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasPressedCallButton, setHasPressedCallButton] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [storeIsClosed, setStoreIsClosed] = useState(false);
  const [isFirstWaitingPeriod, setFirstWaitingPeriod] = useState(true);
  const [permissionsError, setPermissionsError] = useState(false);
  const [callPermitScreen, setcallPermitScreen] = useState(false);
  const [preloadWaitingScreen, setPreloadWaitingScreen] = useState(false);
  const [isSecondWaitingPeriod, setSecondWaitingPeriod] = useState(true)
  const stillThereTimeout = useRef();
  const stillThereInterval = useRef();
  const timeOutRef = useRef();
  const checkStatusInterval = useRef();
  const connectionRef = useRef();
  const checkFocusInterval = useRef();
  const checkCallInitialized = useRef();
  const iosSystem = isIOS();
  
  const goBackChecker = () => {
    window.addEventListener("popstate", function handleBack(e) {
      let state = e.state;

      if (state !== null) {
        alert(t("backWindow"));
        const Guid = localGuid || getKey("guid");
        cancelIssue({ Guid });
        window.removeEventListener("popstate", handleBack);
        history.push(getKey("path"));
      }
    });
  };


  // DOM
  useEffect(() => {
    window.history.pushState({ page_id: 1 }, getKey("path"));
    goBackChecker();
    window.onunload = window.onbeforeunload = askWhenLeaving;
  }, []); 



  useEffect(() => {
    landingOnLoad(localState, history, checkForClosedStore, () => setError(null));
    setWaitingTimer();
 
    return () => clearCall();
  }, [localState, history]);

  //  Cuando se crea la sesion
  useEffect(() => {
    if (options.connected && callPermitScreen && !permissionsError && !storeIsClosed) startCall();
  }, [options.connected]);

  // Cuando una landing quiere arrancar con la camara del cliente apagada
  useEffect(() => {
    if (options.connected) !localState.data.TurnOnCamera && onClickVideo();
  }, [options["publishers"]]);

  // Operador entra en llamda.
  useEffect(() => {
    if (options.meta && options.meta.subscriber.camera && options.active) {
      sendEvent("inicio_videollamada");
      setAlerts({ ... alerts, endCall: false });
    };
    if (options.meta && options.active) recorderController();
  }, [options.meta, options.active]);


  const recorderController = () => {
    if (options.meta.subscriber.camera === 1) startRecording({ SessionId:  getKey("SessionId")})
      .then(({ response }) => setKey("ArchiveId", response.ArchiveId));
    else stopRecording({ ArchiveId: getKey("ArchiveId") });
  };

  // TIMERS
  
  // Si llevas +7 min de espera
  const setWaitingTimer = () =>
  (stillThereInterval.current = setInterval(
      () => checkForWaitTime(),
      420000
    ));

  // se fija si el operador no corto (finalizo ticket)
  const setCallStatusChecker = (Guid) =>
    (checkStatusInterval.current = setInterval(
      () => checkForCallStatus(Guid),
      5000
    ));

  // Se fija si el cliente esta o no en la pestaña.
  const setFocusingChecker = (data) =>
    (checkFocusInterval.current = setInterval(
      () => checkClientFocus(data.Guid),
      3000
    ));


  const checkForClosedStore = () => {
    if (localState.config && !localState.config.IsOpen) {
      setStoreIsClosed(true);
      console.log(document.getElementsByClassName("ots-text-chat"))
    }
  };

  // Segundo timer. 
  const checkForWaitTime = () => {
    if (isFirstWaitingPeriod && !activeCameraSubscribers)
      setAlerts({ ...alerts, stillThere: true });
    else {
      activeCameraSubscribers ? endCall() : cancelTicket();
      clearTimeout(stillThereInterval.current);
    }
  };

  // Pegada para revisar el estado del ticket.
  const checkForCallStatus = (Guid) =>
    checkStatus({ Guid }).then((checkStatusResponse) => {
      if (
        !stopCheckingStatus &&
        checkStatusResponse.IdStatus !== 1 &&
        checkStatusResponse.IdStatus !== 3 &&
        checkStatusResponse.IdStatus !== 4 &&
        checkStatusResponse.IdStatus !== 8
      ) {
        stopRecording({ ArchiveId: getKey("ArchiveId") });
        closeConnection();
        sendEvent("fin_videollamada", "fin_videollamada");
        const { routes } = localState;
        const nextRoute = routes.shift();
        if (nextRoute === "externalURL")
          window.open(localState.data.ThanksRedirection, "_blank");
        else
          history.push({
            pathname: nextRoute,
            state: {
              ...localState,
              config: { ...localState.config, guid: Guid },
            },
          });
      }
    });

  const checkClientFocus = (guid) => {
    if (!document.hasFocus()) onMinimize(guid);
    else onMaximize(guid);
  };

  const startCall = () => {
    !hasPressedCallButton &&
      connectionRef.current
        .startCall()
        .then(({ publishers, subscribers, meta }) =>
          setOptions({
            ...options,
            publishers,
            subscribers,
            meta,
            active: true,
          })
        )
        .catch((error) => console.log(error));
  };

  const askWhenLeaving = () => {
    const Guid = localGuid || getKey("guid");
    if (!activeCameraSubscribers) cancelIssue({ Guid })
    .then((response) => {
      if (response.success) alert(t("closeWindow"))
      else alert(t("closeWindow"));
    })
    .then(() => history.push(getKey("path")));
    else finishIssue({ Guid })
    .then((response) => {
      if (response.success) alert(t("closeWindow"));
      else alert(t("closeWindow"));
    })
    .then(() => history.push(getKey("path")));
    return "¿Seguro que quieres salir?";
  };



  const endCall = () => {
    deleteKey("ticketCreated");
    stopRecording({ ArchiveId: getKey("archieveId") });
    closeConnection();
    const { routes } = localState;
    const nextRoute = routes.shift();
    const Guid = localGuid || getKey("guid");
    finishIssue({ Guid })
      .then(() => {
        if (nextRoute === "externalURL")
          window.open(localState.data.ThanksRedirection, "_blank");
        else
          history.push({
            pathname: nextRoute,
            state: {
              ...localState,
              config: { ...localState.config, guid: Guid },
            },
          });
      })
      .catch(() => {
        if (nextRoute === "externalURL")
          window.open(localState.data.ThanksRedirection, "_blank");
        else
          history.push({
            pathname: nextRoute,
            state: {
              ...localState,
              config: { ...localState.config, guid: Guid },
            },
          });
      });
  };

  // Crea la conexion con session config de api.
  const createConnection = (Guid, videoConfig) => {
    const { Name } = localState.submit;
    let otCore;
    const sessionConfig = connectionOptions({ videoConfig, Name });
    otCore = new AccCore(sessionConfig);
    otCore
      .connect()
      .then(() => setOptions({ ...options, connected: true }))
      .catch((error) => {
        console.log(error);
        setError(true);
        setStopCheckingStatus(true);
      });

    setCallStatusChecker(Guid);

    const events = [
      "subscribeToCamera",
      "unsubscribeFromCamera",
      "subscribeToScreen",
      "unsubscribeFromScreen",
      "startScreenShare",
      "endScreenShare",
    ];

    setOptions({ ...options, showChat: false });

    events.forEach((event) =>
      otCore.on(event, ({ publishers, subscribers, meta }) => {
        setOptions({ ...options, publishers, subscribers, meta, active: true });
        actionForEndSharingOnIos(event);
      })
    );

    otCore.on("messageReceived", () => messagesFormatter("received", urlInbox));
    otCore.on("messageSent", () => messagesFormatter("sended", urlFormatter));
    setOptions({ ...options });
    connectionRef.current = otCore;
  };

  const closeConnection = () => {
    if (connectionRef.current) connectionRef.current.endCall();
    setOptions({ ...options, active: false });
  };

  // FIX IOS bug
  const actionForEndSharingOnIos = (event) => {
    if (iosSystem && event === "unsubscribeFromScreen") {
      Object.values(options.publishers.camera)[0].cycleVideo();
      setTimeout(() => {
        Object.values(options.publishers.camera)[0].cycleVideo();
      }, 1000);
    };
  };

  // CALL CONTROLS
  const onClickEndCall = () => {
    deleteKey("ticketCreated");
    setAlerts({ ...alerts, endCall: true });
  };

  const onClickAudio = () => {
    connectionRef.current.toggleLocalAudio(!options.localAudioEnabled);
    setOptions({ ...options, localAudioEnabled: !options.localAudioEnabled });
  };

  const onClickVideo = () => {
    connectionRef.current.toggleLocalVideo(!options.localVideoEnabled);
    setOptions({ ...options, localVideoEnabled: !options.localVideoEnabled });
  };

  const onClickChat = () =>
    setOptions({ ...options, showChat: !options.showChat });

  const onClickCamera = () => {
    Object.values(options.publishers.camera)[0]
      .cycleVideo()
      .catch(() => setAlerts({ ...alerts, cameraNotFounded: true }));
  };


  // BUSINNESS API METHODS
  const initIssue = (submitData) =>
    startIssue({ IdIssue: submitData.IdIssue })
      .then(({ response, success }) => {
        setKey("ticketCreated", true);
        sendMouseFlowData(response.IdIssue);
        if (success) {
          sendEvent("Ticket_Vivet", "Contact");
          sendAdwordsEvent(localState.data.PG, localState.data.ConversionCode);
          onSuccessIssueInit(response);
        } else onFailedIssueInit();
      })
      .catch(() => onFailedIssueInit());
      
  const onSuccessIssueInit = (data) => {
    setFocusingChecker(data);

    const { Guid, AppId, SessionId, Token } = data;
    const videoConfig = { AppId, SessionId, Token };
    setKey("guid", Guid);
    setLocalGuid(Guid);
    setKey("AppId", AppId);
    setKey("SessionId", SessionId);
    setKey("Token", Token);

    createConnection(Guid, videoConfig);
  };

  const onFailedIssueInit = () => {
    setError(true);
    setLoading(false);
  };

  const checkForPersonQueue = () => {
    const { IdLanding, IdStore, IdDepartment } = localState.submit;
    maxWait({ IdLanding, IdStore, IdDepartment })
      .then(({ success }) => {
        if (success) checkForPreviousGuid();
        else setStoreIsClosed(true);
      })
      .catch(() => setStoreIsClosed(true));
  };
  
  const cancelTicket = () => {
    setStopCheckingStatus(true);
    closeConnection();
    const Guid = localGuid || getKey("guid") || getKey("issueGuid");
    cancelIssue({ Guid });
    history.push({ pathname: `/${match.params.query}` });
    window.onunload = window.onbeforeunload = null;
  };

  const generateOfflineIssue = () => {
    const { routes } = localState;
    const nextRoute = routes.shift();
    const submitData = localState.submit;
    submitData.Customer = getKey("Guid");
    addDepartmentId(submitData);
    createIssueOffline({ IdIssue: submitData.IdIssue }).then(
      ({ response, success }) => {
        if (success)
          history.push({
            pathname: nextRoute,
            state: {
              ...localState,
              config: { ...localState.config, guid: response.Guid },
            },
          });
      }
    );
  };

  // BUSINNESS EXTRA METHODS
  const clearCall = () => {
    clearInterval(checkStatusInterval.current);
    clearInterval(stillThereInterval.current);
    clearInterval(checkFocusInterval.current);
    clearInterval(checkCallInitialized.current);
    clearTimeout(stillThereTimeout.current);
    clearTimeout(timeOutRef.current);
    deleteKey("guid");
    deleteKey("AppId");
    deleteKey("SessionId");
    deleteKey("Token");
  };

  const sendMouseFlowData = (idIssue) =>
    window._mfq &&
    window._mfq.push(["setVariable", "id_issue", toString(idIssue)]);

  const previousGuid = () => {
    const Guid = getKey("guid");
    const AppId = getKey("AppId");
    const SessionId = getKey("SessionId");
    const Token = getKey("Token");
    const videoConfig = { AppId, SessionId, Token };

    if (Guid && AppId && SessionId && Token) return videoConfig;
  };

  const initCall = (videoConfig) => {
    const Guid = getKey("guid");
    setLocalGuid(Guid);
    createConnection(Guid, videoConfig);
  };

  const addDepartmentId = (submitData) => {
    if (localState.submit["IdDepartment"]) {
      submitData.IdDepartment = localState.submit.IdDepartment;
    };
  };

  const checkForPreviousGuid = () => {
    const submitData = localState.submit;
    const previousUserGuid = previousGuid();

    submitData.Customer = getKey("Guid");
    addDepartmentId(submitData);

    if (!previousUserGuid) initIssue(submitData);
    else initCall(previousUserGuid);
  };

  const getPermissions = () =>
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

  const isOpenStore = () => {
    if (localState.data.Store.length === 1) return localState.data.Store[0].IsOpen;
    else return isOpentStoreOnMultipleChoice(); //Revisar
  };

  const isOpentStoreOnMultipleChoice = () => {
    const currentStoreId = localState.submit.IdStore;
    return localState.data.Store.find(store => store.Guid === currentStoreId);
  };

  const onSelectMediaDevices = () =>
    getPermissions().then(() => {
      setcallPermitScreen(true);
      const isCallIssueType = localState.data.IdIssueType.IdIssueType === 1;
      if (isCallIssueType && isOpenStore()) checkForPersonQueue();
      else setStoreIsClosed(true);
    });
    

  async function onMinimize(guid) {
    const unfocused = await getDataByFirebase("issues", guid);
    if (!unfocused)
      database
        .collection("issues")
        .doc(guid)
        .set({
          minimizado: true,
        });
  }

  async function onMaximize(guid) {
    const unfocused = await getDataByFirebase("issues", guid);
    if (unfocused)
      database
        .collection("issues")
        .doc(guid)
        .set({
          minimizado: false,
        });
  };

  const checkIframeLink = (linkToCheck) => {
    allowIframe({ url: linkToCheck }).then(({ success, response }) => {
      if (success) {
        const url = response.url;
        url && linkToCheck !== url && setLink(url);
        setIsIframeLink(true);
      } else setIsIframeLink(false);
    });
  };

  const openExternalLink = () => {
    if (isIframLink) {
      setShowModal(true);
      setShowFab(false);
    } else window.open(link, "_blank");
  };

  const isOnViewChatButton = () => document.getElementById("enableTextChat");

  const onClickHideModal = () => setShowModal(false);

  const onClickRestart = () => history.push(getKey("path"));

  const onClickChooseAnother = () =>
    history.push({
      pathname: getKey("path"),
      state: {},
    });

  // MESSAGES FORMATEER.
  const urlFormatter = (word, messagesFiltered) => {
    const anchor = document.createElement("a");
    anchor.setAttribute("href", word);
    anchor.setAttribute("target", "_blank");
    const innerText = document.createTextNode("link");
    anchor.appendChild(innerText);
    if (messagesFiltered.length === 1)
      return `${t("shareLinkMessage")} ${anchor.outerHTML}`;
    else return `${anchor.outerHTML}`;
  };

  const urlInbox = (word, messagesFiltered) => {
    setLink(word);
    checkIframeLink(word);
    setShowFab(true);
    return urlFormatter(word, messagesFiltered);
  };

  const inboxText = (word) => {
    setAlerts({ ...alerts, newMessage: true });
    return word;
  };

  const messagesFormatter = (type, urlFormatter) =>
    document.querySelectorAll("span").forEach((message) => {
      const messagesDivided = message.innerHTML.split(" ");
      const messagesFiltered = messagesDivided.filter((word) => word !== "");
      const messagesFormatted = messagesFiltered.map((word) => {
        if (URL_REGEX(word)) return urlFormatter(word, messagesFiltered);
        else return type === "sended" ? word : inboxText(word);
      });
      message.innerHTML = messagesFormatted.join(" ");
    });

  const activeCameraSubscribers =
    options.meta && options.meta.subscriber.camera ? "meta" : 0;

  return !localState ? <Spinner /> : (
    <OpentokRender
      options={options}
      localState={localState}
      loading={loading}
      callPermitScreen={callPermitScreen}
      preloadWaitingScreen={preloadWaitingScreen}
      setShowFab={setShowFab}
      showFab={showFab}
      openExternalLink={openExternalLink}
      showModal={showModal}
      onClickHideModal={onClickHideModal}
      alerts={alerts}
      setAlerts={setAlerts}
      endCall={endCall}
      cancelTicket={cancelTicket}
      closeConnection={closeConnection}
      timeOutRef={timeOutRef}
      setFirstWaitingPeriod={setFirstWaitingPeriod}
      onSelectMediaDevices={onSelectMediaDevices}
      setPreloadWaitingScreen={setPreloadWaitingScreen}
      toggleLocalAudio={onClickAudio}
      onPressEndCallButton={onClickEndCall}
      toggleLocalVideo={onClickVideo}
      isOnViewChatButton={isOnViewChatButton}
      toggleChat={onClickChat}
      toggleCamera={onClickCamera}
      link={link}
      setOptions={setOptions}
      storeIsClosed={storeIsClosed}
      setStoreIsClosed={setStoreIsClosed}
      isFirstWaitingPeriod={isFirstWaitingPeriod}
      generateOfflineIssue={generateOfflineIssue}
      onClickRestart={onClickRestart}
      onClickChooseAnother={onClickChooseAnother}
      permissionsError={permissionsError}
      callError={error}
      isSecondWaitingPeriod={isSecondWaitingPeriod}
      setSecondWaitingPeriod={setSecondWaitingPeriod}
      location={location}
    />
  );
};

export default OpenTok;
