import React from "react";
import { IonLabel, IonCheckbox } from "@ionic/react";

import "./Checkbox.scss";

const Checkbox = ({ checked, className, title, value, onChange }) => {
	return (
		<div className={[className, "useFlexRow checkboxContainer"].join(" ")}>
			<IonCheckbox checked={checked} className="checkbox" mode="md" slot="end" value={value} onIonChange={onChange} />
			{ title &&
				<IonLabel className="checkboxText">{title}</IonLabel>
			}
		</div>
	);
};

export default Checkbox;
