import React from "react";
import { IonImg } from "@ionic/react";

import "./Score.scss";

const Score = ({ img, selected, text, onClick }) => (
	<div className={["imageAndTextContainer", selected && "selected"].join(" ")} onClick={onClick}>
		<IonImg className="smileIcon" src={img} />
		<label className={["centerText imageAndTextMargin", selected && "textSelected"].join(" ")}>{text}</label>
	</div>
);

export default Score;