import { IonFooter, IonImg, IonToolbar } from "@ionic/react";
import React from "react";

import "./Footer.scss";

const Footer = ({relative}) => {
 
	return(
	<IonFooter id="foot" style={relative? {position:"relative"} : null}  className={"footer centerText footerFree" }>
		{/* <IonToolbar color="footer">
			<IonImg className="footerImage" src="/img/logo_liveSupport.png" />
		</IonToolbar> */}
		<div className="footerText">Vivet - www.cubiq.digital</div>
	</IonFooter>
)
}
export default Footer;