import React from "react";
import { IonSelect } from "@ionic/react";
import "./Selector.scss";

const Selector = ({ disabled, children, multiple, placeholder, value, onChange }) => {
	const interfaceOptions = {
		header: "Seleccione una opción"
	};

	return (
		<>
			<IonSelect
				cancelText="Cancelar"
				className="applyCustomBoxStyle selector"
				disabled={disabled}
				interface="alert"
				interfaceOptions={interfaceOptions}
				mode="md"
				multiple={multiple}
				okText="OK"
				placeholder={placeholder}
				value={value}
				onIonChange={onChange}
			>
				{children}
			</IonSelect>
		</>
	);
};

export default Selector;
