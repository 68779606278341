import React from "react";

const AntMediaScreen = ({ showScreen, videoMinimized }) => (
    <video 
        id="screenVideo" 
        muted 
        playsInline 
        autoPlay 
        style={{ 
            display: !showScreen ? "none" : "block",
            height: videoMinimized ? "105px" : "210px", width: videoMinimized ? "75px" : "150px"
        }}/>
);

export default AntMediaScreen;