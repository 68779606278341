import React from "react";
import { IonButton } from "@ionic/react";

import "./Button.scss";

const Button = ({ cancel, className, children, disabled, size, type, onClick, id }) => {

	return (
		<>
			<IonButton id={id} className={[className, "button", cancel && "cancel"].join(" ")} disabled={disabled} size={size} type={type} onClick={onClick} >
				{children}
			</IonButton>
		</>
	);
};

export default Button;
