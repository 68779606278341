import React, { useState } from 'react'

import "./AntMediaNewButtons.css"
import { classByAgent, isMobileAgent, inIframe } from "../../../utils/helpers";
import {chatIcon, endCall, videoCameraOff, videoCameraOn, microphoneOff, microphoneOn, rotateCam, monitor, chevronDownIcon, chevronUpIcon, signal} from "./AntMediaIcons"

function AntMediaNewButtons({
    onClickVideo,
    onClickMic,
    onClickCameraSwap,
    onClickEndCall,
    onClickChat,
    onClickShareScreen,
    cameraOn,
    micOn,
    screenOn,
    chatOn,
    videoMinimized,
    onClickResize,
    callOn,
    showRemoteVideo,
    isOpen,
    setOpen,
    rotate, 
    roundTripTime

}) {

    const sizeIcon = isMobileAgent() ? 30 : 20

    const colorConnection = ()=>{
        let color
        if(roundTripTime < "0.1500") color = "green"
        if(roundTripTime >"0.1501" && roundTripTime < "0.2500") color = "yellow"
        if(roundTripTime > "0.2501" ) color = "red"
        return color
    }

    return (
        <div className={showRemoteVideo ? classByAgent("newButtonsContainerOnCall") : classByAgent("newButtonsContainer")}  >
              { showRemoteVideo && roundTripTime &&  <div className={ `${classByAgent("buttonSignal")} parpadea`}>{signal( colorConnection(),  "35px", "35px")}</div>}
            <div className="amNewButtonsContainer" style={{ width: "60%" }} >
                <div id="closeWS" className={classByAgent("amNewButtonItemClose")} style={{ background: "rgb(250 19 19)" }} onClick={onClickEndCall}>
                    {endCall("white", "30px", "30px")}
                </div>
              {/*  { showRemoteVideo && roundTripTime && <div className='parpadea buttonSignal'  style={{ backgroundColor: colorConnection()}} >  </div>} */}
                <div className={classByAgent("hideNewButtons")} onClick={() => setOpen(!isOpen)}>
                    {isOpen ? chevronUpIcon("white", "20px", "20px") : chevronDownIcon("white", "20px", "20px") }
                </div>
                <div style={{ height: isOpen ? "100%" : "0px", margin: "auto", display: !isOpen ? "none" : "" }}>
                    <div className={classByAgent("amNewButtonItem")} onClick={!screenOn && onClickVideo}>
                        {cameraOn
                            ? videoCameraOn ("black",  `${sizeIcon}px`, `${sizeIcon}px`)
                            : videoCameraOff("black",  `${sizeIcon}px`, `${sizeIcon}px`)
                        }
                    </div>
                    <div className={classByAgent("amNewButtonItem")} onClick={onClickMic}>
                        {micOn
                             ? microphoneOn ("black",  `${sizeIcon}px`, `${sizeIcon}px`)
                             : microphoneOff("black",  `${sizeIcon}px`, `${sizeIcon}px`)
                        }
                    </div>
                    {cameraOn && isMobileAgent() && showRemoteVideo &&
                        <div id="camSwap" className={classByAgent("amNewButtonItem")} onClick={!screenOn && onClickCameraSwap}>
                            {rotateCam("black",  `${sizeIcon}px`, `${sizeIcon}px`)}
                        </div>
                    }
                    {
                        !rotate &&
                        <div className={classByAgent("amNewButtonItem")} onClick={callOn && !chatOn && onClickChat}>
                            {chatIcon((`${chatOn || !callOn ? "grey" : "black"}`), `${sizeIcon}px`, `${sizeIcon}px`)} 
                        </div>

                    }
                    {!isMobileAgent() && !inIframe() && (
                        <div className={classByAgent("amNewButtonItem")} onClick={onClickShareScreen}>
                            {monitor("black",  `${sizeIcon}px`, `${sizeIcon}px`)}
                        </div>
                    )}
                   
                </div>
            </div>

        </div>
    )
}

export default AntMediaNewButtons