import React, { useState, useEffect } from 'react'
import t from "../../../utils/translations";
import "./AntMediaPermissions.css"
import { sendPixelTrack } from "../../../utils/analytics";
import {sendEvent} from "../../../utils/helpers"
import { issueDenied } from '../../../services/api';
import AntMediaAlert from './AntMediaAlert';
import { getKey } from '../../../utils/localStorage';

export  const alertContent = (
    <>
        {t("accessSources")} 
        <img className="AMAlertLockImg" src="/img/lock.png" alt="..." /> 
        {t("accessSourcesTwo")}
    </>
);

export const AntMediaPermissions = ({ onPermissionsGranted }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [update, setUpdate] = useState(true);
    const [dennySended, setDennySended] = useState(false);
    const trackInfo = { value: 1, currency: 'COP', content_name: 'permitir-camara-y-microfono' };

  /*   const devicesNeeded = devices => {
        var audioInput;
        var videoInput;
        devices.forEach(device => {
            if (device.kind === "audioinput") audioInput = true;
            if (device.kind === "videoinput") videoInput = true;
        });
        return audioInput && videoInput; 
    };

    const onAccept = () => {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            if (devicesNeeded(devices)) {
                onPermissionsGranted(true);
                sendEvent("Click camara y micrófono");
                sendPixelTrack("segurosmundial", "InitiateCheckout", trackInfo);  
            };
        });
    };
    
   const constraints = (window.constraints = {
      audio: true,
      video: true,
    });
  
    useEffect(() => {
      setTimeout(() => {
        navigator.mediaDevices.getUserMedia(constraints)
          .then(() => onAccept())
          .catch(() => {
            !dennySended && issueDenied({ Guid: getKey("issueGuid") });
            setDennySended(true);
            setShowAlert(true);
            setUpdate(!update);
          });
      }, 5000);
    }, [update]); */

    // ACA


    return (
        <div className="AMpermitsContainer">
            <img src="/img/waiting-room.png" alt="..."  height="70" />
            <div className="importants" >
                <p >{t("waitoperator")}</p>
                <p style={{ color: "#656565" }}>{t("permitsCall")}</p> 
            </div> 
            <img src={t("gifPermits")} alt="..." height="150" className="AMpermissionsImg" />
            {showAlert && (
                <AntMediaAlert 
                    text={alertContent} 
                    title={t("attention")} 
                    leftButton="Entendido" 
                    onClickLeft={() => setShowAlert(false)}
                />
            )}
        </div>
    );
}


export default AntMediaPermissions