import { IonImg } from "@ionic/react";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

import t from "../utils/translations";
import Button from "../components/Button";
import ShareButton from "../components/ShareButton";
import Spinner from "../components/Spinner";
import PageWrapper from "../components/PageWrapper";

import { URL_REGEXP } from "../constants/validations";
import { createLog } from "../services/api";
import { sendPixelTrack } from "../utils/analytics";
import { domainEndpoint, inIframe} from "../utils/helpers";
import { landingOnLoad } from "../utils/landingLoad";

const Thanks = ({ location, history, match }) => {
	const [loading, setLoading] = useState(true);
	const [localState,] = useState(location.state);

	useEffect(() => {
		landingOnLoad(localState, history, () => onSuccessLoad(), () => setLoading(true))
	}, [localState, history]);

	const onSuccessLoad = () => {
		sendPixelTrack("segurosmundial", "Lead");

		if (localState.data.ThanksRedirection && URL_REGEXP.test(localState.data.ThanksRedirection))
			setTimeout(() => window.location.assign(localState.data.ThanksRedirection), 10000);

		setLoading(false);
	};

	const formatDate = date => moment(date).format("dddd, LL");

	const formatTime = time => moment(time, "HH:mm:ss").format("HH:mm");

	const socialNetworks = ["facebook", "whatsapp", "twitter"];

	const sendingLog = (guid, socialNetwork) => {
		switch (socialNetwork) {
			case "facebook":
				return { Guid: guid, Facebook: 1 }
			case "whatsapp":
				return { Guid: guid, Whatsapp: 1 }
			default:
				return { Guid: guid, Twitter: 1 }
		};
	};

	const URLToShare = () =>
		localState.data.CustomShareLink
			? localState.data.CustomShareLink
			: `${domainEndpoint}/${match.params.query}`;

	const handleSubmit = () => {
		const { routes } = localState;
		const nextRoute = routes.shift();
		history.push({
			pathname: nextRoute
		});
	};

	const sendCallLog = socialNetwork => {
		const GuidForLog = localState.config.guid
		createLog(sendingLog(GuidForLog, socialNetwork))
			.then(() => console.log("Guid sending successfully"))
			.catch(e => console.log(e));
	};

	return loading ? <Spinner /> : (
		<PageWrapper showFooter={localState.data.ShowFooter} showHeader={true} localState={localState}>
			<div className="centerText marginToHeader">
				<IonImg className="smileIcon" src="/img/share.png" />
			</div>
			<div className="smallMarginHorizontal marginToHeader imageAndTextContainer">
				<h1>{localState.data.ThanksTitle}</h1>
				<h3 className="paragraphEnd">
					{localState.data.ThanksBody} <br />
				{/* 	{`${t("recomendation")}`} */}
				</h3>
				{localState.data.IdIssueType.IdIssueType === 2 && (
					<>
						<div className="applyCustomBoxStyle appointmentScheduleContainer" >
							<div className="useFlexCenter imageAndTextContainer">
								<IonImg className="smileIcon" src={"/img/calendar.png"} />
							</div>
							<div className="columnContainerWithFlex appointmentScheduleTextContainer">
								<label>{localState.config.storeName}</label>
								<label>{formatDate(localState.config.date)}</label>
								<label>De {formatTime(localState.config.initialTime)} a {formatTime(localState.config.endTime)}</label>
								{localState.submit.Quota && (
									<label>{`${t("peopleQuantity")} ${localState.submit.Quota}`}</label>
								)}
							</div>
						</div>
						{!localState.data.HideQr &&
								<IonImg className="qr" src={localState.config.qr} />
						}
					</>
				)}
				{localState.data.AllowShare && (
					<div className="scoreContainer shareContainer">
						{socialNetworks.map(sn =>
							<ShareButton
								key={sn}
								name={sn}
								text={localState.data.Name}
								url={URLToShare()}
								onClick={() => sendCallLog(sn)}
							/>
						)}
					</div>
				)}
				<Button className="alignSelfCenter" size={"large"} onClick={handleSubmit}>
					{ !inIframe() && <FontAwesomeIcon className="arrowIconAdjust" icon={faSync} size={"xs"} />  }	
					{t("newQuery")}
				</Button>
			</div>
		</PageWrapper>
	);
};

export default Thanks;
